import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { Button } from '@/shared/Button';
import { Input } from '@/shared/Input';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { closeModal } from '@/redux/modal/modal.slice';
import { showToast } from '@/utils/showToast';
import { Switch } from '@/shared/Switch';
import { LayerStoreInterface, LayerUpdateInterface } from '@/redux/layers/layers.types';
import {
  addLayerForModel,
  getLayer,
  getLayersForModel,
  updateLayer,
} from '@/redux/layers/layers.thunks';
import { SerializedError } from '@reduxjs/toolkit';
import { layerFactoryStateSelector } from '@/redux/modal/modal.selector';
import './LayerFactoryModal.styles.css';
import { LoadingIndicator } from '@/shared/LoadingIndicator';

export const LayerFactoryModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentModelId = useAppSelector(currentModelIdSelector);
  const layerFactoryState = useAppSelector(layerFactoryStateSelector);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [data, setData] = useState<LayerStoreInterface>({
    name: '',
    visible: false,
    locked: false,
    modelId: currentModelId,
  });

  const fetchData = useMemo(() => {
    return async (layerId: number): Promise<void> => {
      const layer = await dispatch(getLayer({ modelId: currentModelId, layerId })).unwrap();
      if (layer) {
        setData({
          name: layer.name,
          visible: layer.visible,
          locked: layer.locked,
          modelId: currentModelId,
        });
      }
      setLoaded(true);
    };
  }, [currentModelId, dispatch]);

  useEffect(() => {
    if (layerFactoryState.id) {
      fetchData(layerFactoryState.id);
    } else {
      setLoaded(true);
    }
  }, [fetchData, layerFactoryState.id]);

  const handleChange = (value: string | boolean, key: string): void => {
    setData(prevData => ({ ...prevData, [key]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      event.preventDefault();
      if (layerFactoryState.id) {
        const payload = {
          ...data,
          layerId: layerFactoryState.id,
        } as LayerUpdateInterface;
        await dispatch(updateLayer(payload)).unwrap();
        showToast({
          type: 'success',
          message: 'The layer has been successfully updated',
        });
      } else {
        await dispatch(addLayerForModel(data)).unwrap();
        showToast({
          type: 'success',
          message: 'A new layer has been successfully added',
        });
      }
      dispatch(closeModal());
      dispatch(getLayersForModel(currentModelId));
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while adding a new layer',
      });
    }
  };

  return (
    <div className="relative w-[400px] border border-t-[#E1E0E6] bg-white p-[24px]">
      {!loaded && (
        <div className="c-layer-factory-loader">
          <LoadingIndicator width={44} height={44} />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <label className="mb-6 block text-sm font-semibold" htmlFor="name">
          Name:
          <Input
            type="text"
            id="name"
            data-testid="layer-factory-name"
            placeholder="Layer name here..."
            value={data.name}
            onChange={event => {
              handleChange(event.target.value, 'name');
            }}
            className="mt-2.5 text-sm font-medium text-font-400"
          />
        </label>
        <label
          htmlFor="visible"
          className="mb-6 flex items-center justify-between text-sm font-semibold"
        >
          Visible:
          <Switch
            id="visible"
            data-testid="layer-factory-visible"
            isChecked={data.visible}
            onToggle={value => handleChange(value, 'visible')}
          />
        </label>
        <label
          htmlFor="locked"
          className="mb-6 flex items-center justify-between text-sm font-semibold"
        >
          Locked:
          <Switch
            id="locked"
            data-testid="layer-factory-locked"
            isChecked={data.locked}
            onToggle={value => handleChange(value, 'locked')}
          />
        </label>
        <Button
          type="submit"
          className="w-full justify-center text-base font-medium"
          data-testid="submit"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
