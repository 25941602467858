import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getGlyphs } from '@/redux/glyphs/glyphs.thunks';
import { GlyphsState } from '@/redux/glyphs/glyphs.types';

export const getGlyphsReducer = (builder: ActionReducerMapBuilder<GlyphsState>): void => {
  builder.addCase(getGlyphs.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getGlyphs.fulfilled, (state, action) => {
    state.data = action.payload || {};
    state.loading = 'succeeded';
  });
  builder.addCase(getGlyphs.rejected, state => {
    state.loading = 'failed';
  });
};
