/* eslint-disable no-magic-numbers */
import getDividedExtents from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getDividedExtents';
import findLargestExtent from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/findLargestExtent';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import { Extent } from 'ol/extent';
import {
  COMPLEX_SBO_TERMS,
  MAP_ELEMENT_TYPES,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import isFeatureInCompartment from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/isFeatureInCompartment';

export default function handleSemanticView({
  vectorSource,
  feature,
  resolution,
  sboTerm,
  compartmentId,
  complexId,
}: {
  vectorSource: VectorSource;
  feature: Feature;
  resolution: number;
  sboTerm: string;
  compartmentId: number | null;
  complexId?: number | null;
}): { cover: boolean; hide: boolean; largestExtent: Extent | null } {
  const featureId = feature.getId();
  if (!featureId) {
    return { cover: false, hide: true, largestExtent: null };
  }
  const type = feature.get('type');
  const getMapExtent = feature.get('getMapExtent');
  let coverRatio = 1;
  let cover = false;
  let hide = false;
  let largestExtent: Extent | null = null;
  if (
    getMapExtent instanceof Function &&
    (type === MAP_ELEMENT_TYPES.COMPARTMENT || COMPLEX_SBO_TERMS.includes(sboTerm))
  ) {
    const mapExtent = getMapExtent(resolution);
    const featureExtent = feature.getGeometry()?.getExtent();
    if (featureExtent && mapExtent) {
      const mapArea = Math.abs(mapExtent[2] - mapExtent[0]) * Math.abs(mapExtent[3] - mapExtent[1]);
      const compartmentArea =
        Math.abs(featureExtent[2] - featureExtent[0]) *
        Math.abs(featureExtent[3] - featureExtent[1]);
      coverRatio = compartmentArea / mapArea;
      if (coverRatio < 0.05) {
        cover = true;
        let remainingExtents = [featureExtent];
        vectorSource.forEachFeatureIntersectingExtent(featureExtent, intersectingFeature => {
          if (
            intersectingFeature.get('type') === MAP_ELEMENT_TYPES.COMPARTMENT &&
            intersectingFeature.get('zIndex') > feature.get('zIndex') &&
            intersectingFeature.get('filled') &&
            !isFeatureInCompartment(+featureId, vectorSource, intersectingFeature)
          ) {
            const intersectingFeatureExtent = intersectingFeature.getGeometry()?.getExtent();
            if (intersectingFeatureExtent) {
              remainingExtents = getDividedExtents(remainingExtents, intersectingFeatureExtent);
            }
          }
        });
        largestExtent = findLargestExtent(remainingExtents) || featureExtent;
      }
      (feature as Feature).set('filled', cover);
    }
  }

  if (complexId) {
    const complex = vectorSource.getFeatureById(complexId);
    if (complex && complex.get('filled')) {
      hide = true;
    }
  }
  if (compartmentId) {
    const compartment = vectorSource.getFeatureById(compartmentId);
    if (compartment && compartment.get('filled')) {
      hide = true;
    }
  }

  return { cover, hide, largestExtent };
}
