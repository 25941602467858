/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { MapInstance } from '@/types/map';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import {
  BLACK_COLOR,
  MAP_ELEMENT_TYPES,
  TRANSPARENT_COLOR,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import Polygon from 'ol/geom/Polygon';
import BaseMultiPolygon from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/BaseMultiPolygon';
import getStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStyle';
import { Color } from '@/types/models';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';
import getFill from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getFill';
import { rgbToHex } from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/rgbToHex';
import { MapSize } from '@/redux/map/map.types';
import getStroke from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStroke';

export type CompartmentPathwayProps = {
  id: number;
  complexId?: number | null;
  compartmentId: number | null;
  sboTerm: string;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  fillColor?: Color;
  borderColor?: Color;
  fontColor?: Color;
  outerWidth?: number;
  text?: string;
  fontSize?: number;
  nameX: number;
  nameY: number;
  nameHeight: number;
  nameWidth: number;
  nameVerticalAlign?: VerticalAlign;
  nameHorizontalAlign?: HorizontalAlign;
  overlaysVisible: boolean;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  vectorSource: VectorSource;
  mapBackgroundType: number;
  mapSize: MapSize;
};

export default class CompartmentPathway extends BaseMultiPolygon {
  outerWidth: number;

  overlaysVisible: boolean;

  constructor({
    id,
    complexId,
    compartmentId,
    sboTerm,
    x,
    y,
    width,
    height,
    zIndex,
    fillColor = WHITE_COLOR,
    borderColor = BLACK_COLOR,
    fontColor = BLACK_COLOR,
    outerWidth = 1,
    text = '',
    fontSize = 12,
    nameX,
    nameY,
    nameHeight,
    nameWidth,
    nameVerticalAlign = 'MIDDLE',
    nameHorizontalAlign = 'CENTER',
    overlaysVisible,
    pointToProjection,
    mapInstance,
    vectorSource,
    mapBackgroundType,
    mapSize,
  }: CompartmentPathwayProps) {
    super({
      type: MAP_ELEMENT_TYPES.COMPARTMENT,
      id,
      complexId,
      compartmentId,
      sboTerm,
      x,
      y,
      width,
      height,
      zIndex,
      text,
      fontSize,
      nameX,
      nameY,
      nameWidth,
      nameHeight,
      fontColor,
      nameVerticalAlign,
      nameHorizontalAlign,
      fillColor,
      borderColor,
      pointToProjection,
      overlaysVisible,
      vectorSource,
      mapBackgroundType,
      mapSize,
      mapInstance,
    });
    this.outerWidth = outerWidth;
    this.overlaysVisible = overlaysVisible;
    this.createPolygons();
    this.drawText();
    this.drawMultiPolygonFeature(mapInstance);
  }

  protected createPolygons(): void {
    const compartmentPolygon = new Polygon([
      [
        this.pointToProjection({ x: this.x, y: this.y }),
        this.pointToProjection({ x: this.x + this.width, y: this.y }),
        this.pointToProjection({ x: this.x + this.width, y: this.y + this.height }),
        this.pointToProjection({ x: this.x, y: this.y + this.height }),
        this.pointToProjection({ x: this.x, y: this.y }),
      ],
    ]);
    compartmentPolygon.set('type', MAP_ELEMENT_TYPES.COMPARTMENT);
    this.coverStyle = new Style({
      geometry: compartmentPolygon,
      fill: getFill({ color: rgbToHex({ ...this.fillColor, alpha: 255 }) }),
    });

    compartmentPolygon.set(
      'strokeStyle',
      getStroke({
        color: rgbToHex(this.borderColor),
        width: this.outerWidth,
      }),
    );
    this.styles.push(
      getStyle({
        geometry: compartmentPolygon,
        borderColor: this.borderColor,
        fillColor: this.overlaysVisible ? TRANSPARENT_COLOR : { ...this.fillColor, alpha: 9 },
        lineWidth: this.outerWidth,
        zIndex: this.zIndex,
      }),
    );
    this.polygons.push(compartmentPolygon);
  }
}
