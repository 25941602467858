import { ZERO } from '@/constants/common';
import {
  currentDrawerBioEntityRelatedSubmapSelector,
  currentDrawerBioEntitySelector,
  currentDrawerElementCommentsSelector,
} from '@/redux/bioEntity/bioEntity.selectors';
import {
  getChemicalsForBioEntityDrawerTarget,
  getDrugsForBioEntityDrawerTarget,
} from '@/redux/drawer/drawer.thunks';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { DrawerHeading } from '@/shared/DrawerHeading';
import { ElementSearchResultType } from '@/types/models';
import { CommentItem } from '@/components/Map/Drawer/BioEntityDrawer/Comments/CommentItem.component';
import { getTypeBySBOTerm } from '@/utils/bioEntity/getTypeBySBOTerm';
import { ModificationResidueItem } from '@/components/Map/Drawer/BioEntityDrawer/ModificationResidueItem';
import React from 'react';
import { AnnotationItemList } from '@/components/Map/Drawer/BioEntityDrawer/AnnotationItem/AnnotationItemList.component';
import { CollapsibleSection } from '../ExportDrawer/CollapsibleSection';
import { AssociatedSubmap } from './AssociatedSubmap';
import { ChemicalsList } from './ChemicalsList';
import { DrugsList } from './DrugsList';
import { OverlayData } from './OverlayData';

const TARGET_PREFIX: ElementSearchResultType = `ALIAS`;

export const BioEntityDrawer = (): React.ReactNode => {
  const dispatch = useAppDispatch();
  const bioEntityData = useAppSelector(currentDrawerBioEntitySelector);
  const commentsData = useAppSelector(currentDrawerElementCommentsSelector);
  const relatedSubmap = useAppSelector(currentDrawerBioEntityRelatedSubmapSelector);
  const currentTargetId = bioEntityData?.id ? `${TARGET_PREFIX}:${bioEntityData.id}` : '';
  const fetchChemicalsForTarget = (): void => {
    dispatch(getChemicalsForBioEntityDrawerTarget(currentTargetId));
  };
  const fetchDrugsForTarget = (): void => {
    dispatch(getDrugsForBioEntityDrawerTarget(currentTargetId));
  };

  if (!bioEntityData) {
    return null;
  }

  const isCommentAvailable = commentsData.length > ZERO;
  const modificationResidues = (
    bioEntityData.modificationResidues ? bioEntityData.modificationResidues : []
  ).filter(modificationResidue => modificationResidue.state && modificationResidue.state !== '');
  const isModificationAvailable = modificationResidues.length > ZERO;

  const type = getTypeBySBOTerm(bioEntityData.sboTerm, bioEntityData.shape);

  return (
    <div className="h-calc-drawer" data-testid="bioentity-drawer">
      <DrawerHeading
        title={
          <>
            <span className="font-normal">{type}:</span>&nbsp;
            {bioEntityData.name}
          </>
        }
      />
      <div className="flex max-h-full flex-col gap-6 overflow-y-auto p-6">
        <div className="text-sm font-normal">
          Compartment:{' '}
          <b className="font-semibold">
            {bioEntityData.compartmentName ? bioEntityData.compartmentName : 'default'}
          </b>
        </div>
        {bioEntityData.fullName && (
          <div className="text-sm font-normal">
            Full name: <b className="font-semibold">{bioEntityData.fullName}</b>
          </div>
        )}
        {bioEntityData.notes && (
          <span>
            <hr className="border-b border-b-divide" />
            <div
              className="text-sm font-normal"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: bioEntityData.notes }}
            />
          </span>
        )}
        {isModificationAvailable && (
          <h3 className="font-semibold">Post-translational modifications:</h3>
        )}
        {isModificationAvailable && (
          <ul className="ml-5 list-disc">
            {modificationResidues.map(residue => (
              <ModificationResidueItem key={residue.id} state={residue.state} name={residue.name} />
            ))}
          </ul>
        )}
        <AnnotationItemList references={bioEntityData.references} />
        <AssociatedSubmap />
        {!relatedSubmap && (
          <>
            <CollapsibleSection title="Drugs for target" onOpened={fetchDrugsForTarget}>
              <DrugsList />
            </CollapsibleSection>
            <CollapsibleSection title="Chemicals for target" onOpened={fetchChemicalsForTarget}>
              <ChemicalsList />
            </CollapsibleSection>
          </>
        )}
        <OverlayData
          isShowGroupedOverlays={Boolean(relatedSubmap)}
          isShowOverlayBioEntityName={Boolean(relatedSubmap)}
        />
        {isCommentAvailable && <div className="font-bold"> Comments</div>}
        {isCommentAvailable &&
          commentsData.map(comment => <CommentItem key={comment.id} comment={comment} />)}
      </div>
    </div>
  );
};
