import { modelsDataSelector } from '@/redux/models/models.selectors';
import type { RootState } from '@/redux/store';
import { MapModel } from '@/types/models';
import { Action } from '@reduxjs/toolkit';
import { getModelIdFromAction } from './getModelIdFromAction';

export const getUpdatedModel = (action: Action, state: RootState): MapModel | undefined => {
  const models = modelsDataSelector(state);
  const payloadModelId = getModelIdFromAction(action);

  return models.find(model => model.id === payloadModelId);
};
