/* eslint-disable no-magic-numbers */
import { Coordinate } from 'ol/coordinate';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import getBezierCurve from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getBezierCurve';
import getCoordsX from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getCoordsX';
import getCoordsY from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getCoordsY';
import getCurveCoords from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getCurveCoords';
import { ShapeRelAbs, ShapeRelAbsBezierPoint } from '@/types/models';

export default function getPolygonCoords({
  points,
  x,
  y,
  height,
  width,
  pointToProjection,
}: {
  points: Array<ShapeRelAbs | ShapeRelAbsBezierPoint>;
  x: number;
  y: number;
  height: number;
  width: number;
  pointToProjection: UsePointToProjectionResult;
}): Array<Coordinate> {
  let lastPoint: Coordinate;
  return points.flatMap(point => {
    if ('absoluteX' in point) {
      const coordsX = getCoordsX(
        x,
        point.absoluteX,
        point.relativeX,
        point.relativeHeightForX,
        height,
        width,
      );
      const coordsY = getCoordsY(
        y,
        point.absoluteY,
        point.relativeY,
        point.relativeWidthForY,
        height,
        width,
      );
      lastPoint = pointToProjection({ x: coordsX, y: coordsY });
      return [[...lastPoint]];
    }
    const { p1, p2, p3 } = getCurveCoords({ x, y, point, height, width, pointToProjection });
    const p0 = lastPoint;
    lastPoint = p3;
    let numPoints = 3;
    if (p0[0] === p3[0] || p0[1] === p3[1]) {
      numPoints = 9;
    }
    return getBezierCurve({ p0, p1, p2, p3, numPoints });
  });
}
