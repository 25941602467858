/* eslint-disable no-magic-numbers */
import { PayloadAction } from '@reduxjs/toolkit';
import { MAP_EDIT_ACTIONS } from '@/redux/mapEditTools/mapEditTools.constants';
import { MapEditToolsState } from '@/redux/mapEditTools/mapEditTools.types';
import { LayerImage } from '@/types/models';

export const mapEditToolsSetActiveActionReducer = (
  state: MapEditToolsState,
  action: PayloadAction<keyof typeof MAP_EDIT_ACTIONS | null>,
): void => {
  if (state.activeAction !== action.payload) {
    state.activeAction = action.payload;
  } else {
    state.activeAction = null;
  }
};

export const mapEditToolsSetLayerObjectReducer = (
  state: MapEditToolsState,
  action: PayloadAction<LayerImage | null>,
): void => {
  state.layerImageObject = action.payload;
};
