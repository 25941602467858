interface TrashIconProps {
  className?: string;
}

export const TrashIcon = ({ className }: TrashIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 6H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M9 4H15V6H9V4Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect
      x="6"
      y="6"
      width="12"
      height="14"
      rx="1"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
    />
    <path d="M9 10V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M12 10V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M15 10V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
