import backgroundsReducer from '@/redux/backgrounds/backgrounds.slice';
import bioEntityReducer from '@/redux/bioEntity/bioEntity.slice';
import chemicalsReducer from '@/redux/chemicals/chemicals.slice';
import configurationReducer from '@/redux/configuration/configuration.slice';
import constantReducer from '@/redux/constant/constant.slice';
import contextMenuReducer from '@/redux/contextMenu/contextMenu.slice';
import cookieBannerReducer from '@/redux/cookieBanner/cookieBanner.slice';
import drawerReducer from '@/redux/drawer/drawer.slice';
import drugsReducer from '@/redux/drugs/drugs.slice';
import mapReducer from '@/redux/map/map.slice';
import modalReducer from '@/redux/modal/modal.slice';
import modelsReducer from '@/redux/models/models.slice';
import shapesReducer from '@/redux/shapes/shapes.slice';
import glyphsReducer from '@/redux/glyphs/glyphs.slice';
import modelElementsReducer from '@/redux/modelElements/modelElements.slice';
import layersReducer from '@/redux/layers/layers.slice';
import oauthReducer from '@/redux/oauth/oauth.slice';
import overlayBioEntityReducer from '@/redux/overlayBioEntity/overlayBioEntity.slice';
import overlaysReducer from '@/redux/overlays/overlays.slice';
import projectReducer from '@/redux/project/project.slice';
import projectsReducer from '@/redux/projects/projects.slice';
import reactionsReducer from '@/redux/reactions/reactions.slice';
import newReactionsReducer from '@/redux/newReactions/newReactions.slice';
import searchReducer from '@/redux/search/search.slice';
import userReducer from '@/redux/user/user.slice';
import mapEditToolsReducer from '@/redux/mapEditTools/mapEditTools.slice';
import {
  autocompleteChemicalReducer,
  autocompleteDrugReducer,
  autocompleteSearchReducer,
} from '@/redux/autocomplete/autocomplete.slice';
import {
  AnyAction,
  ListenerEffectAPI,
  ThunkDispatch,
  TypedStartListening,
  configureStore,
} from '@reduxjs/toolkit';
import commentReducer from '@/redux/comment/comment.slice';
import compartmentPathwaysReducer from './compartmentPathways/compartmentPathways.slice';
import entityNumberReducer from './entityNumber/entityNumber.slice';
import exportReducer from './export/export.slice';
import legendReducer from './legend/legend.slice';
import { mapListenerMiddleware } from './map/middleware/map.middleware';
import markersReducer from './markers/markers.slice';
import { errorListenerMiddleware } from './middlewares/error.middleware';
import pluginsReducer from './plugins/plugins.slice';
import publicationsReducer from './publications/publications.slice';
import statisticsReducer from './statistics/statistics.slice';

export const reducers = {
  autocompleteSearch: autocompleteSearchReducer,
  autocompleteDrug: autocompleteDrugReducer,
  autocompleteChemical: autocompleteChemicalReducer,
  search: searchReducer,
  project: projectReducer,
  projects: projectsReducer,
  drugs: drugsReducer,
  chemicals: chemicalsReducer,
  bioEntity: bioEntityReducer,
  comment: commentReducer,
  drawer: drawerReducer,
  modal: modalReducer,
  map: mapReducer,
  backgrounds: backgroundsReducer,
  overlays: overlaysReducer,
  models: modelsReducer,
  shapes: shapesReducer,
  glyphs: glyphsReducer,
  modelElements: modelElementsReducer,
  layers: layersReducer,
  reactions: reactionsReducer,
  newReactions: newReactionsReducer,
  contextMenu: contextMenuReducer,
  cookieBanner: cookieBannerReducer,
  user: userReducer,
  mapEditTools: mapEditToolsReducer,
  configuration: configurationReducer,
  constant: constantReducer,
  overlayBioEntity: overlayBioEntityReducer,
  legend: legendReducer,
  statistics: statisticsReducer,
  compartmentPathways: compartmentPathwaysReducer,
  publications: publicationsReducer,
  export: exportReducer,
  plugins: pluginsReducer,
  markers: markersReducer,
  entityNumber: entityNumberReducer,
  oauth: oauthReducer,
};

export const middlewares = [mapListenerMiddleware.middleware, errorListenerMiddleware.middleware];

export const store = configureStore({
  reducer: reducers,
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(...middlewares),
});

export type StoreType = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type TypedDispatch<T> = ThunkDispatch<T, unknown, AnyAction>;
export type AppDispatch = TypedDispatch<RootState>;
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>;
