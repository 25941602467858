import { createSlice } from '@reduxjs/toolkit';
import { MODAL_INITIAL_STATE } from './modal.constants';
import {
  closeModalReducer,
  openLoginModalReducer,
  openModalReducer,
  openOverviewImagesModalByIdReducer,
  openMolArtModalByIdReducer,
  setOverviewImageIdReducer,
  openPublicationsModalReducer,
  openEditOverlayModalReducer,
  openLoggedInMenuModalReducer,
  openAddCommentModalReducer,
  openErrorReportModalReducer,
  openAccessDeniedModalReducer,
  openSelectProjectModalReducer,
  openLicenseModalReducer,
  openToSModalReducer,
  openLayerFactoryModalReducer,
  openLayerImageObjectFactoryModalReducer,
  openLayerImageObjectEditFactoryModalReducer,
  openLayerTextFactoryModalReducer,
} from './modal.reducers';

const modalSlice = createSlice({
  name: 'modal',
  initialState: MODAL_INITIAL_STATE,
  reducers: {
    openModal: openModalReducer,
    closeModal: closeModalReducer,
    openOverviewImagesModalById: openOverviewImagesModalByIdReducer,
    openMolArtModalById: openMolArtModalByIdReducer,
    setOverviewImageId: setOverviewImageIdReducer,
    openLoginModal: openLoginModalReducer,
    openAddCommentModal: openAddCommentModalReducer,
    openPublicationsModal: openPublicationsModalReducer,
    openEditOverlayModal: openEditOverlayModalReducer,
    openLoggedInMenuModal: openLoggedInMenuModalReducer,
    openErrorReportModal: openErrorReportModalReducer,
    openAccessDeniedModal: openAccessDeniedModalReducer,
    openSelectProjectModal: openSelectProjectModalReducer,
    openLicenseModal: openLicenseModalReducer,
    openToSModal: openToSModalReducer,
    openLayerFactoryModal: openLayerFactoryModalReducer,
    openLayerImageObjectFactoryModal: openLayerImageObjectFactoryModalReducer,
    openLayerImageObjectEditFactoryModal: openLayerImageObjectEditFactoryModalReducer,
    openLayerTextFactoryModal: openLayerTextFactoryModalReducer,
  },
});

export const {
  openModal,
  closeModal,
  openOverviewImagesModalById,
  setOverviewImageId,
  openAddCommentModal,
  openMolArtModalById,
  openLoginModal,
  openPublicationsModal,
  openEditOverlayModal,
  openLoggedInMenuModal,
  openErrorReportModal,
  openAccessDeniedModal,
  openSelectProjectModal,
  openLicenseModal,
  openToSModal,
  openLayerFactoryModal,
  openLayerImageObjectFactoryModal,
  openLayerImageObjectEditFactoryModal,
  openLayerTextFactoryModal,
} = modalSlice.actions;

export default modalSlice.reducer;
