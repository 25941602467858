import { LayerImage, LayerText } from '@/types/models';
import { MapInstance } from '@/types/map';

export default function drawElementOnLayer({
  mapInstance,
  activeLayer,
  object,
  drawFunctionKey,
}: {
  mapInstance: MapInstance;
  activeLayer: number;
  object: LayerImage | LayerText;
  drawFunctionKey: string;
}): void {
  mapInstance?.getAllLayers().forEach(layer => {
    if (layer.get('id') === activeLayer) {
      const drawObject = layer.get(drawFunctionKey);
      if (drawObject instanceof Function || typeof drawObject === 'function') {
        drawObject(object);
      }
    }
  });
}
