/* eslint-disable no-magic-numbers */
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import { AppDispatch } from '@/redux/store';
import Transform from 'ol-ext/interaction/Transform';
import { Geometry } from 'ol/geom';
import { Collection, Feature } from 'ol';
import BaseEvent from 'ol/events/Event';
import { updateLayerImageObject } from '@/redux/layers/layers.thunks';
import { layerUpdateImage } from '@/redux/layers/layers.slice';
import getBoundingBoxFromExtent from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getBoundingBoxFromExtent';
import { MapSize } from '@/redux/map/map.types';
import { Extent } from 'ol/extent';
import { mapEditToolsSetLayerObject } from '@/redux/mapEditTools/mapEditTools.slice';

export default function getTransformImageInteraction(
  dispatch: AppDispatch,
  mapSize: MapSize,
  modelId: number,
  activeLayer: number,
  featuresCollection: Collection<Feature<Geometry>>,
  restrictionExtent: Extent,
): Transform {
  const transform = new Transform({
    features: featuresCollection,
    scale: true,
    rotate: false,
    stretch: false,
    keepRectangle: true,
    translate: true,
    translateBBox: true,
    noFlip: true,
  });

  transform.on('translating', (event: BaseEvent | Event) => {
    const transformEvent = event as unknown as { feature: Feature };
    const { feature } = transformEvent;
    const geometry = feature.getGeometry();
    if (geometry) {
      const extent = geometry.getExtent();
      const [minX, minY, maxX, maxY] = extent;

      const width = maxX - minX;
      const height = maxY - minY;

      const correctedMinX = Math.min(
        restrictionExtent[2] - width,
        Math.max(restrictionExtent[0], minX),
      );
      const correctedMinY = Math.min(
        restrictionExtent[3] - height,
        Math.max(restrictionExtent[1], minY),
      );

      const dx = correctedMinX - minX;
      const dy = correctedMinY - minY;

      if (dx !== 0 || dy !== 0) {
        geometry.translate(dx, dy);
        geometry.changed();
        transform.setSelection(new Collection([feature]));
      }
    }
  });

  transform.on('scaling', (event: BaseEvent | Event) => {
    const transformEvent = event as unknown as { feature: Feature };
    const { feature } = transformEvent;
    const geometry = feature.getGeometry();

    if (!geometry) {
      return;
    }

    const extent = geometry.getExtent();
    const [minX, minY, maxX, maxY] = extent;
    const newMinX = Math.max(minX, restrictionExtent[0]);
    const newMinY = Math.max(minY, restrictionExtent[1]);
    const newMaxX = Math.min(maxX, restrictionExtent[2]);
    const newMaxY = Math.min(maxY, restrictionExtent[3]);
    const newExtent = [newMinX, newMinY, newMaxX, newMaxY];

    const hasChanged = newExtent.some((value, index) => value !== extent[index]);

    if (hasChanged) {
      const newGeometry = fromExtent(newExtent);
      newGeometry.scale(1, -1);
      feature.setGeometry(newGeometry);
      transform.setSelection(new Collection([feature]));
    }
  });

  transform.on('select', event => {
    const transformEvent = event as unknown as { features: Collection<Feature> };
    const { features } = transformEvent;
    if (!features.getLength()) {
      dispatch(mapEditToolsSetLayerObject(null));
      return;
    }
    const getGlyphData = features.item(0).get('getGlyphData');
    if (getGlyphData && getGlyphData instanceof Function) {
      const glyphData = getGlyphData();
      dispatch(mapEditToolsSetLayerObject(glyphData));
    }
  });

  transform.on(['scaleend', 'translateend'], async (event: BaseEvent | Event): Promise<void> => {
    const transformEvent = event as unknown as { feature: Feature };
    const { feature } = transformEvent;
    const setCoordinates = feature.get('setCoordinates');
    const getGlyphData = feature.get('getGlyphData');
    const refreshPolygon = feature.get('refreshPolygon');
    const geometry = feature.getGeometry();
    if (geometry && getGlyphData instanceof Function) {
      const glyphData = getGlyphData();
      try {
        const boundingBox = getBoundingBoxFromExtent(geometry.getExtent(), mapSize);
        const layerImage = await dispatch(
          updateLayerImageObject({ modelId, layerId: activeLayer, ...glyphData, ...boundingBox }),
        ).unwrap();
        if (layerImage) {
          dispatch(layerUpdateImage({ modelId, layerId: activeLayer, layerImage }));
          dispatch(mapEditToolsSetLayerObject(layerImage));
        }
        if (geometry instanceof Polygon && setCoordinates instanceof Function) {
          setCoordinates(geometry.getCoordinates());
          geometry.changed();
        }
      } catch {
        if (refreshPolygon instanceof Function) {
          refreshPolygon();
        }
      }
    }
  });

  return transform;
}
