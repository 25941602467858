import { apiPath } from '@/redux/apiPath';
import { PUBLICATIONS_FETCHING_ERROR_PREFIX } from '@/redux/publications/publications.constatns';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { FilteredPageOf, Publication } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { getErrorMessage } from '@/utils/getErrorMessage';
import { showToast } from '@/utils/showToast';
import { pageableSchema } from '@/models/pageableSchema';
import { publicationSchema } from '@/models/publicationsSchema';

interface Args {
  length: number;
}

export const getBasePublications = async ({ length }: Args): Promise<Publication[]> => {
  try {
    const response = await axiosInstanceNewAPI.get<FilteredPageOf<Publication>>(
      apiPath.getPublications({ params: { length } }),
    );

    const isDataValid = validateDataUsingZodSchema(
      response.data,
      pageableSchema(publicationSchema),
    );

    return isDataValid ? response.data.content : [];
  } catch (error) {
    const errorMessage = getErrorMessage({ error, prefix: PUBLICATIONS_FETCHING_ERROR_PREFIX });
    showToast({
      type: 'error',
      message: errorMessage,
    });
    return [];
  }
};
