import { EMPTY_ARRAY_STRING, FIRST_ARRAY_ELEMENT } from '@/constants/common';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { modelsNameMapSelector } from '@/redux/models/models.selectors';
import { publicationsListDataSelector } from '@/redux/publications/publications.selectors';
import { getPublications } from '@/redux/publications/publications.thunks';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { useEffect, useMemo } from 'react';
import { PublicationsModalLayout } from './PublicationsModalLayout';
import {
  PublicationsTable,
  PublicationsTableData,
} from './PublicationsTable/PublicationsTable.component';

export const PublicationsModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(publicationsListDataSelector);
  const mapsNames = useAppSelector(modelsNameMapSelector);

  const parsedData: PublicationsTableData[] | undefined = useMemo(() => {
    const dd = data?.map(item => ({
      pubmedId: item.article.pubmedId,
      title: item.article.title,
      authors: item.article.authors,
      journal: item.article.journal,
      year: item.article.year,
      elementsOnMap: JSON.stringify(item.elements) || EMPTY_ARRAY_STRING, // table data accepts only string | string[]
      submaps: mapsNames[item.elements[FIRST_ARRAY_ELEMENT].model],
    }));
    return dd || [];
  }, [data, mapsNames]);

  useEffect(() => {
    if (!data) {
      dispatch(getPublications({ params: {} }));
    }
  }, [data, dispatch]);

  return (
    <PublicationsModalLayout>
      <div className="flex w-full flex-1 flex-col items-center justify-center overflow-hidden bg-white">
        {data ? <PublicationsTable data={parsedData} /> : <LoadingIndicator />}
      </div>
    </PublicationsModalLayout>
  );
};
