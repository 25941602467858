import spinnerIcon from '@/assets/vectors/icons/spinner.svg';
import { PROJECT_ID } from '@/constants';
import { openOverlaysDrawer, openSearchDrawerWithSelectedTab } from '@/redux/drawer/drawer.slice';
import { AppDispatch, store } from '@/redux/store';
import { QueryData } from '@/types/query';
import { getDefaultSearchTab } from '@/components/FunctionalArea/TopBar/SearchBar/SearchBar.utils';
import { PluginsManager } from '@/services/pluginsManager';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ZERO } from '@/constants/common';
import { getConstant } from '@/redux/constant/constant.thunks';
import {
  getChemicalAutocomplete,
  getDrugAutocomplete,
  getSearchAutocomplete,
} from '@/redux/autocomplete/autocomplete.thunks';
import { openSelectProjectModal } from '@/redux/modal/modal.slice';
import { getProjects } from '@/redux/projects/projects.thunks';
import { getSubmapConnectionsBioEntity } from '@/redux/bioEntity/thunks/getSubmapConnectionsBioEntity';
import { getArrowTypes, getLineTypes, getShapes } from '@/redux/shapes/shapes.thunks';
import { MATOMO_URL } from '@/redux/configuration/configuration.constants';
import {
  USER_ACCEPTED_COOKIES_COOKIE_VALUE,
  USER_ACCEPTED_MATOMO_COOKIES_COOKIE_NAME,
} from '@/components/FunctionalArea/CookieBanner/CookieBanner.constants';
import { injectMatomoTracking } from '@/utils/injectMatomoTracking';
import { getGlyphs } from '@/redux/glyphs/glyphs.thunks';
import { getAllBackgroundsByProjectId } from '../backgrounds/backgrounds.thunks';
import { getConfiguration, getConfigurationOptions } from '../configuration/configuration.thunks';
import {
  initMapBackground,
  initMapPosition,
  initMapSizeAndModelId,
  initOpenedMaps,
} from '../map/map.thunks';
import { getModels } from '../models/models.thunks';
import { getInitOverlays } from '../overlayBioEntity/overlayBioEntity.thunk';
import {
  getAllPublicOverlaysByProjectId,
  getAllUserOverlaysByCreator,
} from '../overlays/overlays.thunks';
import { getAllPlugins, getInitPlugins } from '../plugins/plugins.thunks';
import { getProjectById, setProjectId } from '../project/project.thunks';
import { setPerfectMatch } from '../search/search.slice';
import { getSearchData } from '../search/search.thunks';
import { getStatisticsById } from '../statistics/statistics.thunks';
import { getSessionValid } from '../user/user.thunks';
import { openPluginsDrawer, setCurrentDrawerPluginHash } from '../plugins/plugins.slice';

interface InitializeAppParams {
  queryData: QueryData;
}

export const fetchInitialAppData = createAsyncThunk<
  void,
  InitializeAppParams,
  { dispatch: AppDispatch }
>('appInit/fetchInitialAppData', async ({ queryData }, { dispatch }): Promise<void> => {
  dispatch(setProjectId({ queryData }));

  /** Fetch all data required for rendering map */

  await Promise.all([
    dispatch(getConstant()),
    dispatch(getConfiguration()),
    dispatch(getConfigurationOptions()),
    dispatch(getProjectById(PROJECT_ID)),
    dispatch(getAllBackgroundsByProjectId(PROJECT_ID)),
    dispatch(getAllPublicOverlaysByProjectId(PROJECT_ID)),
    dispatch(getModels()),
    dispatch(getShapes()),
    dispatch(getGlyphs()),
    dispatch(getLineTypes()),
    dispatch(getArrowTypes()),
  ]);

  try {
    const configuration = store.getState().configuration.main.data;

    const userAcceptedMatomo =
      localStorage.getItem(USER_ACCEPTED_MATOMO_COOKIES_COOKIE_NAME) ===
      USER_ACCEPTED_COOKIES_COOKIE_VALUE.ACCEPTED;

    if (configuration && userAcceptedMatomo) {
      const options = configuration.options.filter(option => option.type === MATOMO_URL);
      let url = '';
      if (options.length > ZERO) {
        url = options[ZERO].value ? options[ZERO].value : '';
      }
      if (!url.startsWith('http')) {
        url = '';
      }
      injectMatomoTracking(url);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  if (queryData.pluginsId) {
    await dispatch(
      getInitPlugins({
        pluginsId: queryData.pluginsId,
        setHashedPlugin: PluginsManager.setHashedPlugin,
      }),
    );

    const hash = queryData.pluginsId[ZERO];

    if (hash) {
      dispatch(openPluginsDrawer());
      dispatch(setCurrentDrawerPluginHash(hash));
    }
  }

  /**  Set map properties to allow rendering. If map params (modelId,backgroundId,position) are not provided in query -> it will be set to map default */
  await Promise.all([
    dispatch(initMapSizeAndModelId({ queryData })),
    dispatch(initMapPosition({ queryData })),
    dispatch(initMapBackground({ queryData })),
  ]);
  /** Create tabs for maps / submaps */
  dispatch(initOpenedMaps({ queryData }));

  // Check if auth token is valid
  await dispatch(getSessionValid());

  // Fetch data needed for export
  dispatch(getStatisticsById(PROJECT_ID));

  // Fetch plugins list
  dispatch(getAllPlugins());

  // autocomplete
  dispatch(getSearchAutocomplete());
  dispatch(getDrugAutocomplete());
  dispatch(getChemicalAutocomplete());

  dispatch(getSubmapConnectionsBioEntity());

  /** Trigger search */
  if (queryData.searchValue) {
    dispatch(setPerfectMatch(queryData.perfectMatch));
    dispatch(
      getSearchData({
        searchQueries: queryData.searchValue,
        isPerfectMatch: queryData.perfectMatch,
      }),
    );
    dispatch(openSearchDrawerWithSelectedTab(getDefaultSearchTab(queryData.searchValue)));
  }

  await dispatch(getAllUserOverlaysByCreator());
  /** fetch overlays  */
  if (queryData.overlaysId) {
    dispatch(getInitOverlays({ overlaysId: queryData.overlaysId }));

    if (!queryData.searchValue) {
      dispatch(openOverlaysDrawer());
    }
  }
  if (queryData.oauthLogin === 'success') {
    await dispatch(getProjects());
    dispatch(openSelectProjectModal());
  }
  new Image().src = spinnerIcon.src;
});
