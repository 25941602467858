import { createSlice } from '@reduxjs/toolkit';
import { MAP_EDIT_TOOLS_STATE_INITIAL_MOCK } from '@/redux/mapEditTools/mapEditTools.mock';
import {
  mapEditToolsSetActiveActionReducer,
  mapEditToolsSetLayerObjectReducer,
} from '@/redux/mapEditTools/mapEditTools.reducers';

export const layersSlice = createSlice({
  name: 'layers',
  initialState: MAP_EDIT_TOOLS_STATE_INITIAL_MOCK,
  reducers: {
    mapEditToolsSetActiveAction: mapEditToolsSetActiveActionReducer,
    mapEditToolsSetLayerObject: mapEditToolsSetLayerObjectReducer,
  },
});

export const { mapEditToolsSetActiveAction, mapEditToolsSetLayerObject } = layersSlice.actions;

export default layersSlice.reducer;
