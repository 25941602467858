/* eslint-disable no-magic-numbers */
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { Select } from '@/shared/Select';
import {
  layersActiveLayerSelector,
  layersForCurrentModelSelector,
  layersVisibilityForCurrentModelSelector,
} from '@/redux/layers/layers.selectors';
import { useEffect, useMemo } from 'react';
import { setActiveLayer } from '@/redux/layers/layers.slice';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { mapEditToolsSetActiveAction } from '@/redux/mapEditTools/mapEditTools.slice';

export const MapActiveLayerSelector = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const layers = useAppSelector(layersForCurrentModelSelector);
  const layersVisibility = useAppSelector(layersVisibilityForCurrentModelSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);
  const activeLayer = useAppSelector(layersActiveLayerSelector);

  const handleChange = (activeLayerId: string | number): void => {
    dispatch(setActiveLayer({ modelId: currentModelId, layerId: +activeLayerId }));
  };

  const options: Array<{ id: number; name: string }> = useMemo(() => {
    return layers
      .filter(layer => layersVisibility[layer.details.id])
      .map(layer => {
        return {
          id: layer.details.id,
          name: layer.details.name,
        };
      });
  }, [layers, layersVisibility]);

  useEffect(() => {
    const selectedOption = options.find(option => option.id === activeLayer) || null;
    if (selectedOption || !currentModelId) {
      return;
    }
    if (options.length === 0) {
      dispatch(setActiveLayer({ modelId: currentModelId, layerId: null }));
    } else {
      dispatch(setActiveLayer({ modelId: currentModelId, layerId: options[0].id }));
    }
  }, [activeLayer, currentModelId, dispatch, options]);

  useEffect(() => {
    if (!options.length) {
      dispatch(setActiveLayer({ modelId: currentModelId, layerId: null }));
      dispatch(mapEditToolsSetActiveAction(null));
    }
  }, [currentModelId, dispatch, options]);

  return (
    <div className={twMerge('absolute right-6 top-[calc(64px+40px+84px)] flex')}>
      {Boolean(options.length) && (
        <Select options={options} selectedId={activeLayer} onChange={handleChange} width={140} />
      )}
    </div>
  );
};
