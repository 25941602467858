import { createSlice } from '@reduxjs/toolkit';
import { LAYERS_STATE_INITIAL_MOCK } from '@/redux/layers/layers.mock';
import {
  getLayersForModelReducer,
  layerAddImageReducer,
  layerAddTextReducer,
  layerDeleteImageReducer,
  layerUpdateImageReducer,
  setActiveLayerReducer,
  setLayerVisibilityReducer,
} from '@/redux/layers/layers.reducers';

export const layersSlice = createSlice({
  name: 'layers',
  initialState: LAYERS_STATE_INITIAL_MOCK,
  reducers: {
    setLayerVisibility: setLayerVisibilityReducer,
    setActiveLayer: setActiveLayerReducer,
    layerAddImage: layerAddImageReducer,
    layerUpdateImage: layerUpdateImageReducer,
    layerDeleteImage: layerDeleteImageReducer,
    layerAddText: layerAddTextReducer,
  },
  extraReducers: builder => {
    getLayersForModelReducer(builder);
  },
});

export const {
  setLayerVisibility,
  setActiveLayer,
  layerAddImage,
  layerUpdateImage,
  layerDeleteImage,
  layerAddText,
} = layersSlice.actions;

export default layersSlice.reducer;
