import { apiPath } from '@/redux/apiPath';
import { Glyph, PageOf } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { glyphSchema } from '@/models/glyphSchema';
import { GLYPHS_FETCHING_ERROR_PREFIX } from '@/redux/glyphs/glyphs.constants';
import { pageableSchema } from '@/models/pageableSchema';

export const getGlyphs = createAsyncThunk<Glyph[], void, ThunkConfig>('getGlyphs', async () => {
  try {
    const { data } = await axiosInstanceNewAPI.get<PageOf<Glyph>>(apiPath.getGlyphs());
    const isDataValid = validateDataUsingZodSchema(data, pageableSchema(glyphSchema));
    if (!isDataValid) {
      return [];
    }
    return data.content;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: GLYPHS_FETCHING_ERROR_PREFIX }));
  }
});

export const addGlyph = createAsyncThunk<Glyph | undefined, File, ThunkConfig>(
  'addGlyph',
  async file => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await axiosInstanceNewAPI.post<Glyph>(apiPath.addGlyph(), formData);
      const isDataValid = validateDataUsingZodSchema(data, glyphSchema);
      if (!isDataValid) {
        return undefined;
      }
      return data;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);
