/* eslint-disable no-magic-numbers */

export const TEXT_FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96];

export const TEXT_HORIZONTAL_ALIGNMENTS = [
  { id: 'LEFT', name: 'left' },
  { id: 'RIGHT', name: 'right' },
  { id: 'CENTER', name: 'center' },
  { id: 'END', name: 'end' },
  { id: 'START', name: 'start' },
];

export const TEXT_VERTICAL_ALIGNMENTS = [
  { id: 'TOP', name: 'top' },
  { id: 'MIDDLE', name: 'middle' },
  { id: 'BOTTOM', name: 'bottom' },
];

export const DEFAULT_TEXT_FONT_SIZE = 12;
export const DEFAULT_HORIZONTAL_ALIGNMENT = TEXT_HORIZONTAL_ALIGNMENTS[0].id;
export const DEFAULT_VERTICAL_ALIGNMENT = TEXT_VERTICAL_ALIGNMENTS[0].id;
