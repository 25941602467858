import React, { ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import { Button } from '@/shared/Button';
import './Question.styles.css';
import { QuestionIcon } from '@/shared/Icon/Icons/QuestionIcon';

type QuestionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  question: string;
};

const QuestionModal = ({
  isOpen,
  onClose,
  onConfirm,
  question,
}: QuestionModalProps): null | ReactPortal => {
  if (!isOpen) return null;

  const domElement = document.getElementById('modal-root');

  if (!domElement) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="c-question-overlay">
      <div className="c-question-modal">
        <QuestionIcon size={94} />
        <h1 className="text-center text-2xl font-semibold">{question}</h1>
        <div className="flex w-full justify-center gap-10">
          <Button
            type="submit"
            className="w-[100px] justify-center text-base font-medium"
            variantStyles="remove"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            type="submit"
            className="w-[100px] justify-center text-base font-medium"
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>,
    domElement,
  );
};

export default QuestionModal;
