/* eslint-disable no-magic-numbers */
import { MAP_EDIT_ACTIONS } from '@/redux/mapEditTools/mapEditTools.constants';
import { mapEditToolsSetActiveAction } from '@/redux/mapEditTools/mapEditTools.slice';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapEditToolsActiveActionSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { MapDrawActionsButton } from '@/components/Map/MapDrawActions/MapDrawActionsButton.component';
import { MapDrawEditActionsComponent } from '@/components/Map/MapDrawActions/MapDrawEditActions.component';
import { useMemo } from 'react';
import {
  layersForCurrentModelSelector,
  layersVisibilityForCurrentModelSelector,
} from '@/redux/layers/layers.selectors';

export const MapDrawActions = (): React.JSX.Element | null => {
  const activeAction = useAppSelector(mapEditToolsActiveActionSelector);
  const dispatch = useAppDispatch();
  const layers = useAppSelector(layersForCurrentModelSelector);
  const layersVisibility = useAppSelector(layersVisibilityForCurrentModelSelector);

  const toggleMapEditAction = (action: keyof typeof MAP_EDIT_ACTIONS): void => {
    dispatch(mapEditToolsSetActiveAction(action));
  };

  const visibleLayersLength: number = useMemo(() => {
    return layers.filter(layer => layersVisibility[layer.details.id]).length;
  }, [layers, layersVisibility]);

  if (visibleLayersLength === 0) {
    return null;
  }

  return (
    <div className="absolute right-6 top-[calc(64px+40px+144px)] z-10 flex flex-col items-end gap-4">
      <MapDrawActionsButton
        isActive={activeAction === MAP_EDIT_ACTIONS.DRAW_IMAGE}
        toggleMapEditAction={() => toggleMapEditAction(MAP_EDIT_ACTIONS.DRAW_IMAGE)}
        icon="image"
        title="Draw image"
      />
      <MapDrawActionsButton
        isActive={activeAction === MAP_EDIT_ACTIONS.ADD_TEXT}
        toggleMapEditAction={() => toggleMapEditAction(MAP_EDIT_ACTIONS.ADD_TEXT)}
        icon="text"
        title="Add text"
      />
      <MapDrawEditActionsComponent
        isActive={activeAction === MAP_EDIT_ACTIONS.TRANSFORM_IMAGE}
        toggleMapEditAction={() => toggleMapEditAction(MAP_EDIT_ACTIONS.TRANSFORM_IMAGE)}
      />
    </div>
  );
};
