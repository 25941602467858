/* eslint-disable no-magic-numbers */
import React from 'react';
import { Textarea } from '@/shared/Textarea';
import { Select } from '@/shared/Select';
import {
  TEXT_FONT_SIZES,
  TEXT_HORIZONTAL_ALIGNMENTS,
  TEXT_VERTICAL_ALIGNMENTS,
} from '@/components/FunctionalArea/Modal/LayerTextFactoryModal/LayerTextFactory.constants';
import { LayerTextFactoryForm } from '@/components/FunctionalArea/Modal/LayerTextFactoryModal/LayerTextFactory.types';
import { ColorTilePicker } from '@/shared/ColorPicker/ColorTilePicker.component';
import hexToRgbIntAlpha from '@/utils/convert/hexToRgbIntAlpha';
import { Color } from '@/types/models';

type LayerTextFormProps = {
  data: LayerTextFactoryForm;
  onChange: (value: number | string | Color, key: string) => void;
};

export const LayerTextForm = ({ data, onChange }: LayerTextFormProps): React.JSX.Element => {
  const fontSizes = TEXT_FONT_SIZES.map(fontSize => {
    return {
      id: fontSize,
      name: fontSize.toString(),
    };
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-5 gap-2">
        <div>
          <span>Font size:</span>
          <Select
            options={fontSizes}
            selectedId={data.fontSize}
            listClassName="max-h-48"
            testId="font-size-select"
            onChange={value => onChange(value, 'fontSize')}
          />
        </div>
        <div>
          <span>Horizontal alignment:</span>
          <Select
            options={TEXT_HORIZONTAL_ALIGNMENTS}
            selectedId={data.horizontalAlign}
            testId="horizontal-alignment-select"
            onChange={value => onChange(value, 'horizontalAlign')}
          />
        </div>
        <div>
          <span>Vertical alignment:</span>
          <Select
            options={TEXT_VERTICAL_ALIGNMENTS}
            selectedId={data.verticalAlign}
            testId="vertical-alignment-select"
            onChange={value => onChange(value, 'verticalAlign')}
          />
        </div>
        <div>
          <span>Color:</span>
          <ColorTilePicker colorChange={color => onChange(hexToRgbIntAlpha(color), 'color')} />
        </div>
        <div>
          <span>Border color:</span>
          <ColorTilePicker
            colorChange={color => onChange(hexToRgbIntAlpha(color), 'borderColor')}
          />
        </div>
      </div>
      <Textarea
        value={data.notes}
        onChange={event => onChange(event.target.value, 'notes')}
        placeholder="Text here..."
        rows={4}
        className="mb-4 text-sm font-medium text-font-400"
      />
    </div>
  );
};
