/* eslint-disable no-magic-numbers */
import { z as zod } from 'zod';
import { apiPath } from '@/redux/apiPath';
import { Layer, LayerImage, Layers, LayerText } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { layerSchema } from '@/models/layerSchema';
import { LAYERS_FETCHING_ERROR_PREFIX } from '@/redux/layers/layers.constants';
import {
  LayerStoreInterface,
  LayersVisibilitiesState,
  LayerUpdateInterface,
} from '@/redux/layers/layers.types';
import { layerTextSchema } from '@/models/layerTextSchema';
import { layerRectSchema } from '@/models/layerRectSchema';
import { pageableSchema } from '@/models/pageableSchema';
import { layerOvalSchema } from '@/models/layerOvalSchema';
import { layerLineSchema } from '@/models/layerLineSchema';
import { layerImageSchema } from '@/models/layerImageSchema';
import arrayToKeyValue from '@/utils/array/arrayToKeyValue';
import { BoundingBox } from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import { LayerTextFactoryForm } from '@/components/FunctionalArea/Modal/LayerTextFactoryModal/LayerTextFactory.types';

export const getLayer = createAsyncThunk<
  Layer | null,
  { modelId: number; layerId: number },
  ThunkConfig
>('vectorMap/getLayer', async ({ modelId, layerId }) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<Layer>(apiPath.getLayer(modelId, layerId));

    const isDataValid = validateDataUsingZodSchema(data, layerSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const getLayersForModel = createAsyncThunk<
  LayersVisibilitiesState | undefined,
  number,
  ThunkConfig
>('vectorMap/getLayers', async (modelId: number) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<Layers>(apiPath.getLayers(modelId));
    const isDataValid = validateDataUsingZodSchema(data, pageableSchema(layerSchema));
    if (!isDataValid) {
      return undefined;
    }
    let layers = await Promise.all(
      data.content.map(async (layer: Layer) => {
        const [textsResponse, rectsResponse, ovalsResponse, linesResponse, imagesResponse] =
          await Promise.all([
            axiosInstanceNewAPI.get(apiPath.getLayerTexts(modelId, layer.id)),
            axiosInstanceNewAPI.get(apiPath.getLayerRects(modelId, layer.id)),
            axiosInstanceNewAPI.get(apiPath.getLayerOvals(modelId, layer.id)),
            axiosInstanceNewAPI.get(apiPath.getLayerLines(modelId, layer.id)),
            axiosInstanceNewAPI.get(apiPath.getLayerImages(modelId, layer.id)),
          ]);
        return {
          details: layer,
          texts: arrayToKeyValue(textsResponse.data.content as Array<LayerText>, 'id'),
          rects: rectsResponse.data.content,
          ovals: ovalsResponse.data.content,
          lines: linesResponse.data.content,
          images: arrayToKeyValue(imagesResponse.data.content as Array<LayerImage>, 'id'),
        };
      }),
    );
    layers = layers.filter(layer => {
      return (
        zod.array(layerTextSchema).safeParse(Object.values(layer.texts)).success &&
        zod.array(layerRectSchema).safeParse(layer.rects).success &&
        zod.array(layerOvalSchema).safeParse(layer.ovals).success &&
        zod.array(layerLineSchema).safeParse(layer.lines).success &&
        zod.array(layerImageSchema).safeParse(Object.values(layer.images)).success
      );
    });
    const layersVisibility = layers.reduce((acc: { [key: string]: boolean }, layer) => {
      acc[layer.details.id] = layer.details.visible;
      return acc;
    }, {});
    let activeLayer = null;
    const activeLayers = layers.filter(layer => layer.details.visible);
    if (activeLayers.length) {
      activeLayer = activeLayers[0].details.id;
    }
    return {
      layers,
      layersVisibility,
      activeLayer,
    };
  } catch (error) {
    return Promise.reject(getError({ error, prefix: LAYERS_FETCHING_ERROR_PREFIX }));
  }
});

export const addLayerForModel = createAsyncThunk<Layer | null, LayerStoreInterface, ThunkConfig>(
  'vectorMap/addLayer',
  async ({ name, visible, locked, modelId }) => {
    try {
      const { data } = await axiosInstanceNewAPI.post<Layer>(apiPath.storeLayer(modelId), {
        name,
        visible,
        locked,
      });

      const isDataValid = validateDataUsingZodSchema(data, layerSchema);

      return isDataValid ? data : null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const updateLayer = createAsyncThunk<Layer | null, LayerUpdateInterface, ThunkConfig>(
  'vectorMap/updateLayer',
  async ({ name, visible, locked, modelId, layerId }) => {
    try {
      const { data } = await axiosInstanceNewAPI.put<Layer>(apiPath.updateLayer(modelId, layerId), {
        name,
        visible,
        locked,
      });

      const isDataValid = validateDataUsingZodSchema(data, layerSchema);

      return isDataValid ? data : null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const removeLayer = createAsyncThunk<
  null,
  { modelId: number; layerId: number },
  ThunkConfig
>('vectorMap/removeLayer', async ({ modelId, layerId }) => {
  try {
    await axiosInstanceNewAPI.delete<void>(apiPath.removeLayer(modelId, layerId));
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const addLayerImageObject = createAsyncThunk<
  LayerImage | null,
  {
    modelId: number;
    layerId: number;
    x: number;
    y: number;
    z: number;
    width: number;
    height: number;
    glyph: number | null;
  },
  ThunkConfig
>('vectorMap/addLayerImageObject', async ({ modelId, layerId, x, y, z, width, height, glyph }) => {
  try {
    const { data } = await axiosInstanceNewAPI.post<LayerImage>(
      apiPath.addLayerImageObject(modelId, layerId),
      {
        x,
        y,
        z,
        width,
        height,
        glyph,
      },
    );
    const isDataValid = validateDataUsingZodSchema(data, layerImageSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const updateLayerImageObject = createAsyncThunk<
  LayerImage | null,
  {
    modelId: number;
    layerId: number;
    id: number;
    x: number;
    y: number;
    z: number;
    width: number;
    height: number;
    glyph: number | null;
  },
  ThunkConfig
>(
  'vectorMap/updateLayerImageObject',
  async ({ modelId, layerId, id, x, y, z, width, height, glyph }) => {
    try {
      const { data } = await axiosInstanceNewAPI.put<LayerImage>(
        apiPath.updateLayerImageObject(modelId, layerId, id),
        {
          x,
          y,
          z,
          width,
          height,
          glyph,
        },
      );
      const isDataValid = validateDataUsingZodSchema(data, layerImageSchema);
      if (isDataValid) {
        return data;
      }
      return null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const removeLayerImage = createAsyncThunk<
  null,
  { modelId: number; layerId: number; imageId: number },
  ThunkConfig
>('vectorMap/removeLayerImage', async ({ modelId, layerId, imageId }) => {
  try {
    await axiosInstanceNewAPI.delete<void>(
      apiPath.removeLayerImageObject(modelId, layerId, imageId),
    );
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const addLayerText = createAsyncThunk<
  LayerText | null,
  {
    modelId: number;
    layerId: number;
    z: number;
    boundingBox: BoundingBox;
    textData: LayerTextFactoryForm;
  },
  ThunkConfig
>('vectorMap/addLayerText', async ({ modelId, layerId, z, boundingBox, textData }) => {
  try {
    const { data } = await axiosInstanceNewAPI.post<LayerText>(
      apiPath.addLayerText(modelId, layerId),
      {
        ...boundingBox,
        ...textData,
        z,
      },
    );
    const isDataValid = validateDataUsingZodSchema(data, layerTextSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});
