/* eslint-disable no-magic-numbers */
import { Icon } from '@/shared/Icon';
import type { IconTypes } from '@/types/iconTypes';

type MapDrawActionsButtonProps = {
  isActive: boolean;
  toggleMapEditAction: () => void;
  icon: IconTypes;
  title?: string;
};

export const MapDrawActionsButton = ({
  isActive,
  toggleMapEditAction,
  icon,
  title = '',
}: MapDrawActionsButtonProps): React.JSX.Element => {
  return (
    <button
      type="button"
      className={`flex h-12 w-12 items-center justify-center rounded-full ${
        isActive ? 'bg-primary-100' : 'bg-white drop-shadow-primary'
      }`}
      onClick={() => toggleMapEditAction()}
      title={title}
    >
      <Icon
        className={`h-[28px] w-[28px] ${isActive ? 'text-primary-500' : 'text-black'}`}
        name={icon}
      />
    </button>
  );
};
