import { Export } from '../ExportCompound';

export const Graphics = (): React.ReactNode => {
  return (
    <div data-testid="graphics-tab">
      <Export>
        <Export.Submap />
        <Export.ImageSize />
        <Export.ImageFormat />
        <Export.DownloadGraphics />
      </Export>
    </div>
  );
};
