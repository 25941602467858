type LoadingIndicatorProps = {
  height?: number;
  width?: number;
};

const DEFAULT_HEIGHT = 16;
const DEFAULT_WIDTH = 16;

export const LoadingIndicator = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
}: LoadingIndicatorProps): JSX.Element => (
  <div style={{ width, height }} className="animate-spin" data-testid="loading-indicator">
    <svg width={width} height={height} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        strokeDasharray="90, 150"
        strokeDashoffset="0"
        strokeLinecap="round"
      />
    </svg>
  </div>
);
