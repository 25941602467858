import { closeDrawer, openBioEntityDrawerById, selectTab } from '@/redux/drawer/drawer.slice';
import { AppDispatch } from '@/redux/store';
import { searchFitBounds } from '@/services/pluginsManager/map/triggerSearch/searchFitBounds';
import { ElementSearchResult } from '@/types/models';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { clearBioEntities } from '@/redux/bioEntity/bioEntity.slice';
import { Point } from '@/types/map';
import { getMultiBioEntityByIds } from '@/redux/bioEntity/thunks/getMultiBioEntity';
import { handleOpenImmediateLink } from '@/components/Map/MapViewer/utils/listeners/mapSingleClick/handleOpenImmediateLink';
import { ZERO } from '@/constants/common';
import { findClosestBioEntityPoint } from './findClosestBioEntityPoint';

type SearchConfig = {
  point: Point;
  searchDistance?: string;
  maxZoom: number;
  zoom: number;
  hasFitBounds?: boolean;
  isResultDrawerOpen?: boolean;
};
/* prettier-ignore */

/* prettier-ignore */
export const handleAliasResults =
  (dispatch: AppDispatch, closestSearchResult: ElementSearchResult, { hasFitBounds, maxZoom, point, searchDistance, zoom, isResultDrawerOpen }: SearchConfig) =>
    async ({ id, modelId, type }: ElementSearchResult): Promise<void> => {
      const bioEntities = await dispatch(
        getMultiBioEntityByIds({
          elementsToFetch: [{elementId: id, type, modelId, addNumbersToEntityNumber: true}]
        }),
      ).unwrap();

      if (searchDistance) {

        const matchingBioEntityFound = findClosestBioEntityPoint(bioEntities, searchDistance, maxZoom, zoom, point);

        if (!matchingBioEntityFound) {
          if (isResultDrawerOpen) {
            dispatch(closeDrawer());
          }

          dispatch(clearBioEntities());
          return;
        }

        handleOpenImmediateLink(bioEntities[ZERO]);
      }

      dispatch(selectTab(`${id}`));
      dispatch(openBioEntityDrawerById(id));

      PluginsEventBus.dispatchEvent('onSearch', {
        type: 'bioEntity',
        searchValues: [closestSearchResult],
        results: [bioEntities.map((bioEntity)=>{return {perfect: true, bioEntity};})],
      });

      if (hasFitBounds) {
        searchFitBounds();
      }
    };
