/* eslint-disable no-magic-numbers */
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapEditToolsLayerImageObjectSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { MapDrawActionsButton } from '@/components/Map/MapDrawActions/MapDrawActionsButton.component';
import { openLayerImageObjectEditFactoryModal } from '@/redux/modal/modal.slice';
import { removeLayerImage, updateLayerImageObject } from '@/redux/layers/layers.thunks';
import { mapModelIdSelector } from '@/redux/map/map.selectors';
import { layersActiveLayerSelector } from '@/redux/layers/layers.selectors';
import { layerDeleteImage, layerUpdateImage } from '@/redux/layers/layers.slice';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import { mapEditToolsSetLayerObject } from '@/redux/mapEditTools/mapEditTools.slice';
import updateGlyph from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/Glyph/updateGlyph';
import QuestionModal from '@/components/FunctionalArea/Modal/QuestionModal/QustionModal.component';
import { useState } from 'react';
import { showToast } from '@/utils/showToast';
import { SerializedError } from '@reduxjs/toolkit';
import removeElementFromLayer from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/layer/utils/removeElementFromLayer';

type MapDrawEditActionsComponentProps = {
  toggleMapEditAction: () => void;
  isActive: boolean;
};

export const MapDrawEditActionsComponent = ({
  toggleMapEditAction,
  isActive,
}: MapDrawEditActionsComponentProps): React.JSX.Element => {
  const currentModelId = useAppSelector(mapModelIdSelector);
  const activeLayer = useAppSelector(layersActiveLayerSelector);
  const layerImageObject = useAppSelector(mapEditToolsLayerImageObjectSelector);
  const dispatch = useAppDispatch();
  const { mapInstance } = useMapInstance();

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const editMapObject = (): void => {
    dispatch(openLayerImageObjectEditFactoryModal());
  };

  const removeImage = (): void => {
    setIsRemoveModalOpen(true);
  };

  const updateZIndex = async (value: number): Promise<void> => {
    if (!activeLayer || !layerImageObject) {
      return;
    }
    const layerImage = await dispatch(
      updateLayerImageObject({
        modelId: currentModelId,
        layerId: activeLayer,
        ...layerImageObject,
        z: layerImageObject.z + value,
      }),
    ).unwrap();
    if (layerImage) {
      dispatch(layerUpdateImage({ modelId: currentModelId, layerId: activeLayer, layerImage }));
      dispatch(mapEditToolsSetLayerObject(layerImage));
      updateGlyph(mapInstance, activeLayer, layerImage);
    }
  };

  const rejectRemove = (): void => {
    setIsRemoveModalOpen(false);
  };

  const confirmRemove = async (): Promise<void> => {
    if (!layerImageObject || !activeLayer) {
      return;
    }
    try {
      await dispatch(
        removeLayerImage({
          modelId: currentModelId,
          layerId: activeLayer,
          imageId: layerImageObject.id,
        }),
      ).unwrap();
      dispatch(
        layerDeleteImage({
          modelId: currentModelId,
          layerId: activeLayer,
          imageId: layerImageObject.id,
        }),
      );
      removeElementFromLayer({ mapInstance, layerId: activeLayer, featureId: layerImageObject.id });
      showToast({
        type: 'success',
        message: 'The layer image has been successfully removed',
      });
      setIsRemoveModalOpen(false);
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while removing the layer image',
      });
    }
  };

  return (
    <div className="flex flex-row-reverse gap-4">
      <QuestionModal
        isOpen={isRemoveModalOpen}
        onClose={rejectRemove}
        onConfirm={confirmRemove}
        question="Are you sure you want to remove the image?"
      />
      <MapDrawActionsButton
        isActive={isActive}
        toggleMapEditAction={toggleMapEditAction}
        icon="pencil"
        title="Edit image"
      />
      {layerImageObject && (
        <>
          <MapDrawActionsButton
            isActive={false}
            toggleMapEditAction={() => editMapObject()}
            icon="edit-image"
            title="Edit image"
          />
          <MapDrawActionsButton
            isActive={false}
            toggleMapEditAction={removeImage}
            icon="trash"
            title="Remove image"
          />
          <MapDrawActionsButton
            isActive={false}
            toggleMapEditAction={() => updateZIndex(1)}
            icon="arrow-double-up"
            title="Remove image"
          />
          <MapDrawActionsButton
            isActive={false}
            toggleMapEditAction={() => updateZIndex(-1)}
            icon="arrow-double-down"
            title="Remove image"
          />
        </>
      )}
    </div>
  );
};
