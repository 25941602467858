/* eslint-disable no-magic-numbers */
import React, { useRef, useMemo } from 'react';
import { glyphsDataSelector } from '@/redux/glyphs/glyphs.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { Button } from '@/shared/Button';
import { BASE_NEW_API_URL } from '@/constants';
import { apiPath } from '@/redux/apiPath';
import { Input } from '@/shared/Input';
import Image from 'next/image';
import { Glyph } from '@/types/models';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import './LayerImageObjectForm.styles.css';
import { Autocomplete } from '@/shared/Autocomplete';

type LayerImageObjectFormProps = {
  onSubmit: () => void;
  isSending: boolean;
  selectedGlyph: number | null;
  setSelectedGlyph: (glyphId: number | null) => void;
  file: File | null;
  setFile: (file: File | null) => void;
};

export const LayerImageObjectForm = ({
  onSubmit,
  isSending,
  selectedGlyph,
  setSelectedGlyph,
  file,
  setFile,
}: LayerImageObjectFormProps): React.JSX.Element => {
  const glyphs: Glyph[] = useAppSelector(glyphsDataSelector);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const initialSelectedGlyph = glyphs.find(glyph => glyph.id === selectedGlyph);

  const previewUrl: string | null = useMemo(() => {
    if (selectedGlyph) {
      return `${BASE_NEW_API_URL}${apiPath.getGlyphImage(selectedGlyph)}`;
    }
    if (file) {
      return URL.createObjectURL(file);
    }
    return null;
  }, [file, selectedGlyph]);

  const handleGlyphChange = (glyph: Glyph | null): void => {
    const glyphId = glyph?.id || null;
    setSelectedGlyph(glyphId);
    if (!glyphId) {
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const uploadedFile = e.target.files?.[0] || null;
    setFile(uploadedFile);
    if (!uploadedFile) {
      return;
    }
    setSelectedGlyph(null);
  };

  return (
    <div className="relative w-[800px] border border-t-[#E1E0E6] bg-white p-[24px]">
      {isSending && (
        <div className="c-layer-image-object-factory-loader">
          <LoadingIndicator width={44} height={44} />
        </div>
      )}
      <div className="grid grid-cols-2 gap-2">
        <div className="mb-4 flex flex-col gap-2">
          <span>Glyph:</span>
          <Autocomplete<Glyph>
            options={glyphs}
            initialValue={initialSelectedGlyph}
            valueKey="id"
            labelKey="filename"
            onChange={handleGlyphChange}
          />
        </div>
        <div className="mb-4 flex flex-col gap-2">
          <span>File:</span>
          <Input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            data-testid="image-file-input"
            className="w-full border border-[#ccc] bg-white p-2"
          />
        </div>
      </div>

      <div className="relative mb-4 flex h-[350px] w-full items-center justify-center overflow-hidden rounded border">
        {previewUrl ? (
          <Image
            src={previewUrl}
            alt="image preview"
            fill
            style={{ objectFit: 'contain' }}
            className="rounded"
            data-testid="layer-image-preview"
          />
        ) : (
          <div className="text-gray-500">No Image</div>
        )}
      </div>

      <Button
        type="button"
        onClick={onSubmit}
        className="w-full justify-center text-base font-medium"
      >
        Submit
      </Button>
    </div>
  );
};
