import { MapModel } from '@/types/models';
import { numberToSafeInt } from '@/utils/number/numberToInt';
import { useCallback, useContext, useEffect } from 'react';
import { ExportContext } from '../../ExportCompound.context';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '../ImageSize.constants';
import { ImageSize, ModelAspectRatios } from '../ImageSize.types';
import { useExportGraphicsSelectedModel } from './useExportGraphicsSelectedModel';
import { getModelAspectRatios } from './useModelAspectRatios';

interface UseImageSizeResults {
  handleChangeWidth(width: number): void;
  handleChangeHeight(height: number): void;
  width: number;
  height: number;
}

export const useImageSize = (): UseImageSizeResults => {
  const selectedModel = useExportGraphicsSelectedModel();
  const { data, setImageSize } = useContext(ExportContext);
  const { imageSize } = data;
  const aspectRatios: ModelAspectRatios = getModelAspectRatios(selectedModel);

  const maxWidth = selectedModel?.width || DEFAULT_IMAGE_WIDTH;
  const maxHeight = selectedModel?.height || DEFAULT_IMAGE_HEIGHT;

  const getNormalizedImageSize = useCallback(
    (newImageSize: ImageSize): ImageSize => {
      const newWidth = newImageSize.width;
      const newHeight = newImageSize.height;

      const widthMinMax = Math.min(maxWidth, newWidth);
      const heightMinMax = Math.min(maxHeight, newHeight);

      const widthInt = numberToSafeInt(widthMinMax);
      const heightInt = numberToSafeInt(heightMinMax);

      return {
        width: widthInt,
        height: heightInt,
      };
    },
    [maxWidth, maxHeight],
  );

  const setDefaultModelImageSize = useCallback(
    (model: MapModel): void => {
      const { width, height } = model;
      const newImageSize = getNormalizedImageSize({
        width,
        height,
      });

      setImageSize(newImageSize);
    },
    [getNormalizedImageSize, setImageSize],
  );

  const handleChangeWidth = (width: number): void => {
    const newImageSize = getNormalizedImageSize({
      width,
      height: width / aspectRatios.horizontal,
    });

    setImageSize(newImageSize);
  };

  const handleChangeHeight = (height: number): void => {
    const newImageSize = getNormalizedImageSize({
      height,
      width: height / aspectRatios.vertical,
    });

    setImageSize(newImageSize);
  };

  useEffect(() => {
    if (!selectedModel) {
      return;
    }

    setDefaultModelImageSize(selectedModel);
  }, [setDefaultModelImageSize, selectedModel]);

  return {
    handleChangeWidth,
    handleChangeHeight,
    width: imageSize.width,
    height: imageSize.height,
  };
};
