/* eslint-disable no-magic-numbers */
import { MapSize } from '@/redux/map/map.types';
import { toLonLat } from 'ol/proj';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { Extent } from 'ol/extent';
import { BoundingBox } from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';

export default function getBoundingBoxFromExtent(extent: Extent, mapSize: MapSize): BoundingBox {
  const [startLng, startLat] = toLonLat([extent[0], extent[3]]);
  const startPoint = latLngToPoint([startLat, startLng], mapSize);
  const [endLng, endLat] = toLonLat([extent[2], extent[1]]);
  const endPoint = latLngToPoint([endLat, endLng], mapSize);

  const width = Math.abs(endPoint.x - startPoint.x);
  const height = Math.abs(endPoint.y - startPoint.y);

  return {
    width,
    height,
    x: startPoint.x,
    y: startPoint.y,
  };
}
