import {
  activeOverlaysSelector,
  overlayBioEntityDataSelector,
} from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { store } from '@/redux/store';
import { DataOverlay } from '@/services/pluginsManager/map/overlays/types/DataOverlay';
import { modelsDataSelector } from '@/redux/models/models.selectors';
import { DataOverlayEntry } from '@/services/pluginsManager/map/overlays/types/DataOverlayEntry';

export const getVisibleDataOverlays = (): DataOverlay[] => {
  const activeOverlays = activeOverlaysSelector(store.getState());
  const overlayData = overlayBioEntityDataSelector(store.getState());

  const models = modelsDataSelector(store.getState());

  const dataOverlays = activeOverlays.map(mapOverlay => new DataOverlay(mapOverlay));

  dataOverlays.forEach(dataOverlay => {
    const mapOverlayData = overlayData[dataOverlay.id];
    if (mapOverlayData) {
      models.forEach(model => {
        const entries = mapOverlayData[model.id];
        if (entries) {
          entries.forEach(dataEntry => {
            if (dataEntry.type === 'submap-link') {
              dataOverlay.addEntry(
                new DataOverlayEntry(
                  Number(dataEntry.id),
                  'ALIAS',
                  dataEntry.modelId,
                  dataEntry.color,
                  dataEntry.value,
                ),
              );
            } else {
              // eslint-disable-next-line no-console
              console.log(`${dataEntry.type} not supported`);
            }
          });
        }
      });
    }
  });

  return dataOverlays;
};
