/* eslint-disable no-magic-numbers */
import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '@/redux/root/root.selectors';

export const mapEditToolsSelector = createSelector(rootSelector, state => state.mapEditTools);

export const mapEditToolsActiveActionSelector = createSelector(
  mapEditToolsSelector,
  state => state.activeAction,
);

export const mapEditToolsLayerImageObjectSelector = createSelector(
  mapEditToolsSelector,
  state => state.layerImageObject,
);

export const isMapEditToolsActiveSelector = createSelector(mapEditToolsSelector, state =>
  Boolean(state.activeAction),
);
