import { rootSelector } from '@/redux/root/root.selectors';
import { createSelector } from '@reduxjs/toolkit';
import { projectDataSelector } from '@/redux/project/project.selectors';
import { ZERO } from '@/constants/common';
import { MODEL_ID_DEFAULT } from '../map/map.constants';
import { mapDataSelector } from '../map/map.selectors';

export const modelsSelector = createSelector(rootSelector, state => state.models);

export const modelsDataSelector = createSelector(modelsSelector, models => models?.data || []);

export const currentModelSelector = createSelector(
  modelsDataSelector,
  mapDataSelector,
  (models, mapData) => models.find(model => model.id === mapData.modelId),
);

export const modelsIdsSelector = createSelector(modelsDataSelector, models =>
  models.map(model => model.id),
);

export const modelsNameMapSelector = createSelector(modelsDataSelector, models =>
  models.reduce((acc, model) => ({ ...acc, [model.id]: model.name }), {} as Record<number, string>),
);

export const modelsIdsAndNamesSelector = createSelector(modelsDataSelector, models =>
  models.map(({ id, name }) => ({ id, name })),
);

export const currentModelIdSelector = createSelector(
  currentModelSelector,
  model => model?.id || MODEL_ID_DEFAULT,
);

export const lastClickSelector = createSelector(mapDataSelector, mapData => mapData.lastClick);
export const lastRightClickSelector = createSelector(
  mapDataSelector,
  mapData => mapData.lastRightClick,
);

export const currentModelNameSelector = createSelector(
  currentModelSelector,
  model => model?.name || '',
);

export const modelByIdSelector = createSelector(
  [modelsSelector, (_state, modelId: number): number => modelId],
  (models, modelId) => (models?.data || []).find(({ id }) => id === modelId),
);

export const mainMapModelSelector = createSelector(
  projectDataSelector,
  modelsDataSelector,
  (project, models) => {
    const topMapId = project?.topMap.id;
    return models.filter(model => model.id === topMapId)[ZERO];
  },
);

export const loadingModelsSelector = createSelector(modelsSelector, state => state.loading);

export const mainMapModelDescriptionSelector = createSelector(
  mainMapModelSelector,
  model => model?.description,
);

export const vectorRenderingSelector = createSelector(
  currentModelSelector,
  model => model?.vectorRendering || false,
);
