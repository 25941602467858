import { getCompartmentPathways } from '@/redux/compartmentPathways/compartmentPathways.thunks';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { modelsIdsSelector } from '@/redux/models/models.selectors';
import { DrawerHeading } from '@/shared/DrawerHeading';
import { useEffect, useState } from 'react';
import { CurrentView } from './CurrentView';
import { Elements } from './Elements';
import { Graphics } from './Graphics';
import { Network } from './Network';
import { TabNavigator } from './TabNavigator';
import { TAB_NAMES } from './TabNavigator/TabNavigator.constants';
import { TabNames } from './TabNavigator/TabNavigator.types';

export const ExportDrawer = (): React.ReactNode => {
  const modelsIds = useAppSelector(modelsIdsSelector);
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TabNames>(TAB_NAMES.ELEMENTS);

  const handleTabChange = (tabName: TabNames): void => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    dispatch(getCompartmentPathways(modelsIds));
  }, [dispatch, modelsIds]);

  return (
    <div data-testid="export-drawer" className="h-full max-h-full">
      <DrawerHeading title="Export" />
      <div className="h-[calc(100%-93px)] max-h-[calc(100%-93px)] overflow-y-auto px-6">
        <TabNavigator activeTab={activeTab} onTabChange={handleTabChange} />
        {activeTab === TAB_NAMES.ELEMENTS && <Elements />}
        {activeTab === TAB_NAMES.NETWORK && <Network />}
        {activeTab === TAB_NAMES.GRAPHICS && <Graphics />}
        {activeTab === TAB_NAMES.CURRENT_VIEW && <CurrentView />}
      </div>
    </div>
  );
};
