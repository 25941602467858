import { Button } from '@/shared/Button';
import { Switch } from '@/shared/Switch';

type LayersDrawerLayerActionsProps = {
  editLayer: () => void;
  removeLayer: () => void;
  isChecked: boolean;
  toggleVisibility: (value: boolean) => void;
};

export const LayersDrawerLayerActions = ({
  editLayer,
  removeLayer,
  isChecked,
  toggleVisibility,
}: LayersDrawerLayerActionsProps): JSX.Element => {
  return (
    <div className="flex items-center gap-2">
      <Switch isChecked={isChecked} onToggle={value => toggleVisibility(value)} />
      <Button onClick={() => editLayer()}>Edit</Button>
      <Button onClick={() => removeLayer()} color="error" variantStyles="remove">
        Remove
      </Button>
    </div>
  );
};
