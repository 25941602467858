/* eslint-disable no-magic-numbers */
interface QuestionIconProps {
  className?: string;
  size?: number;
}

export const QuestionIcon = ({ className, size = 20 }: QuestionIconProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="44" stroke="black" strokeWidth="2" fill="none" />
    <path
      d="M50 80a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm1-20H47c0-6.6 3.6-8.8 6.6-10.9 3.4-2.3 5.4-4.5 5.4-8.1 0-5.5-4.5-10-10-10s-10 4.5-10 10H33c0-9.4 7.6-17 17-17s17 7.6 17 17c0 5.5-3.3 8.6-6.9 11.1-2.6 1.7-4.1 3.3-4.1 6.9z"
      fill="black"
      strokeWidth={1}
    />
  </svg>
);
