import { createSlice } from '@reduxjs/toolkit';

import { GLYPHS_STATE_INITIAL_MOCK } from '@/redux/glyphs/glyphs.mock';
import { getGlyphsReducer } from '@/redux/glyphs/glyphs.reducers';

export const glyphsSlice = createSlice({
  name: 'glyphs',
  initialState: GLYPHS_STATE_INITIAL_MOCK,
  reducers: {},
  extraReducers: builder => {
    getGlyphsReducer(builder);
  },
});

export default glyphsSlice.reducer;
