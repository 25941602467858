/* eslint-disable no-magic-numbers */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ZERO } from '@/constants/common';
import { QueryData } from '@/types/query';
import { DEFAULT_ZOOM } from '@/constants/map';
import { getPointMerged } from '@/utils/object/getPointMerged';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import type { AppDispatch, RootState } from '../store';
import {
  InitMapBackgroundActionPayload,
  InitMapBackgroundParams,
  InitMapPositionActionPayload,
  InitMapPositionParams,
  InitMapSizeAndModelIdActionPayload,
  InitMapSizeAndModelIdParams,
  InitOpenedMapsActionPayload,
  InitOpenedMapsProps,
  MapSizeAndModelId,
  OppenedMap,
  Position,
} from './map.types';
import {
  backgroundsDataSelector,
  mainBackgroundsDataSelector,
} from '../backgrounds/background.selectors';
import {
  currentModelSelector,
  mainMapModelSelector,
  modelByIdSelector,
  modelsDataSelector,
} from '../models/models.selectors';
import {
  DEFAULT_POSITION,
  MAIN_MAP,
  INIT_MAP_BACKGROUND_ERROR_PREFIX,
  INIT_MAP_POSITION_ERROR_PREFIX,
  INIT_MAP_SIZE_MODEL_ID_ERROR_PREFIX,
  INIT_OPENED_MAPS_ERROR_PREFIX,
} from './map.constants';

/** UTILS - in the same file because of dependency cycle */

export const getBackgroundId = (state: RootState, queryData: QueryData): number => {
  const mainMapBackground = mainBackgroundsDataSelector(state);
  const backgrounds = backgroundsDataSelector(state);
  let backgroundId = queryData?.backgroundId || mainMapBackground?.id || ZERO;

  if (backgrounds.length > 0) {
    if (
      backgrounds.filter(background => {
        return background.id === backgroundId;
      }).length === 0
    ) {
      backgroundId = backgrounds[ZERO].id;
    }
  }

  if (backgroundId !== mainMapBackground?.id) {
    PluginsEventBus.dispatchEvent('onBackgroundOverlayChange', backgroundId);
  }

  return backgroundId;
};

export const getModelId = (state: RootState, queryData: QueryData): number => {
  const mainMapModel = mainMapModelSelector(state);
  const models = modelsDataSelector(state);
  let modelId = queryData?.modelId || mainMapModel?.id || ZERO;
  if (models.length > 0) {
    if (
      models.filter(model => {
        return model.id === modelId;
      }).length === 0
    ) {
      modelId = models[ZERO].id;
    }
  }
  return modelId;
};

export const getInitMapPosition = (state: RootState, queryData: QueryData): Position => {
  const modelId = getModelId(state, queryData);
  const currentModel = modelByIdSelector(state, modelId);
  const position = queryData?.initialPosition;
  const HALF = 2;

  if (!currentModel) {
    return {
      last: DEFAULT_POSITION,
      initial: DEFAULT_POSITION,
    };
  }

  const defaultPosition = {
    x: currentModel.defaultCenterX ?? currentModel.width / HALF,
    y: currentModel.defaultCenterY ?? currentModel.height / HALF,
    z: currentModel.defaultZoomLevel ?? DEFAULT_ZOOM,
  };

  const mergedPosition = getPointMerged(position || {}, defaultPosition);

  if (mergedPosition.z && mergedPosition.z !== defaultPosition.z) {
    PluginsEventBus.dispatchEvent('onZoomChanged', {
      modelId: currentModel.id,
      zoom: mergedPosition.z,
    });
  }

  if (mergedPosition.x !== defaultPosition.x || mergedPosition.y !== defaultPosition.y) {
    PluginsEventBus.dispatchEvent('onCenterChanged', {
      modelId: currentModel.id,
      x: mergedPosition.x,
      y: mergedPosition.y,
    });
  }

  return {
    last: mergedPosition,
    initial: mergedPosition,
  };
};

export const getInitMapSizeAndModelId = (
  state: RootState,
  queryData: QueryData,
): MapSizeAndModelId => {
  const mainMapModel = mainMapModelSelector(state);
  const modelId = getModelId(state, queryData);
  const currentModel = modelByIdSelector(state, modelId);

  if (modelId !== mainMapModel?.id) {
    PluginsEventBus.dispatchEvent('onSubmapOpen', modelId);
  }

  return {
    modelId: currentModel?.id || ZERO,
    size: {
      width: currentModel?.width || ZERO,
      height: currentModel?.height || ZERO,
      tileSize: currentModel?.tileSize || ZERO,
      minZoom: currentModel?.minZoom || ZERO,
      maxZoom: currentModel?.maxZoom || ZERO,
    },
  };
};

export const getOpenedMaps = (state: RootState, queryData: QueryData): OppenedMap[] => {
  const currentModel = currentModelSelector(state);
  const mainMap = mainMapModelSelector(state);

  const openedMaps: OppenedMap[] = [
    { modelId: mainMap.id, modelName: MAIN_MAP, lastPosition: DEFAULT_POSITION },
  ];

  const isMainMapSetAsCurrentModel = currentModel?.id !== mainMap.id;

  if (isMainMapSetAsCurrentModel) {
    openedMaps.push({
      modelId: currentModel?.id || ZERO,
      modelName: currentModel?.name || '',
      lastPosition: { ...DEFAULT_POSITION, ...queryData.initialPosition },
    });
  }
  return openedMaps;
};

/** THUNKS  */

export const initMapSizeAndModelId = createAsyncThunk<
  InitMapSizeAndModelIdActionPayload,
  InitMapSizeAndModelIdParams,
  { dispatch: AppDispatch; state: RootState } & ThunkConfig
>('map/initMapSizeAndModelId', async ({ queryData }, { getState }) => {
  try {
    const state = getState();

    return getInitMapSizeAndModelId(state, queryData);
  } catch (error) {
    return Promise.reject(getError({ error, prefix: INIT_MAP_SIZE_MODEL_ID_ERROR_PREFIX }));
  }
});

export const initMapPosition = createAsyncThunk<
  InitMapPositionActionPayload,
  InitMapPositionParams,
  { dispatch: AppDispatch; state: RootState } & ThunkConfig
>('map/initMapPosition', async ({ queryData }, { getState }) => {
  try {
    const state = getState();

    return getInitMapPosition(state, queryData);
  } catch (error) {
    return Promise.reject(getError({ error, prefix: INIT_MAP_POSITION_ERROR_PREFIX }));
  }
});

export const initMapBackground = createAsyncThunk<
  InitMapBackgroundActionPayload,
  InitMapBackgroundParams,
  { dispatch: AppDispatch; state: RootState } & ThunkConfig
>('map/initMapBackground', async ({ queryData }, { getState }) => {
  try {
    const state = getState();
    return getBackgroundId(state, queryData);
  } catch (error) {
    return Promise.reject(getError({ error, prefix: INIT_MAP_BACKGROUND_ERROR_PREFIX }));
  }
});

export const initOpenedMaps = createAsyncThunk<
  InitOpenedMapsActionPayload,
  InitOpenedMapsProps,
  { dispatch: AppDispatch; state: RootState } & ThunkConfig
>('appInit/initOpenedMaps', async ({ queryData }, { getState }) => {
  try {
    const state = getState();

    return getOpenedMaps(state, queryData);
  } catch (error) {
    return Promise.reject(getError({ error, prefix: INIT_OPENED_MAPS_ERROR_PREFIX }));
  }
});
