import { z } from 'zod';
import { ZodTypeAny } from 'zod/lib/types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const pageableSchema = <T extends ZodTypeAny>(type: T) =>
  z.object({
    totalPages: z.number().nonnegative(),
    totalElements: z.number().nonnegative(),
    numberOfElements: z.number().nonnegative(),
    size: z.number().nonnegative(),
    number: z.number().nonnegative(),
    content: z.array(type),
  });
