interface ResizeImageIconProps {
  className?: string;
}

export const ResizeImageIcon = ({ className }: ResizeImageIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6" y="2" width="17" height="17" stroke="currentColor" strokeWidth="1.5" fill="none" />
    <rect x="1" y="14" width="9" height="9" stroke="currentColor" strokeWidth="1.5" fill="white" />
    <line x1="10" y1="14" x2="18" y2="7" stroke="currentColor" strokeWidth="1.5" />
    <polygon points="12,5 20,5 20,13" fill="currentColor" strokeWidth="1.5" />
  </svg>
);
