import { Button } from '@/shared/Button';
import { useContext } from 'react';
import { ExportContext } from '../ExportCompound.context';

export const DownloadCurrentView = (): React.ReactNode => {
  const { handleDownloadCurrentView } = useContext(ExportContext);

  return (
    <div className="mt-6">
      <Button onClick={handleDownloadCurrentView}>Download</Button>
    </div>
  );
};
