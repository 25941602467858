import React from 'react';

interface PencilIconProps {
  className?: string;
}

export const PencilIcon = ({ className }: PencilIconProps): JSX.Element => (
  <svg
    className={className}
    fill="currentColor"
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-hidden="true"
    role="img"
    strokeWidth="1"
  >
    <g>
      <path d="M2,18 L0,24 L6,22 L18,10 L14,6 L2,18 Z" />
      <path d="M20,2 L18,4 L22,8 L24,6 L20,2 Z" />
    </g>
  </svg>
);
