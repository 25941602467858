/* eslint-disable no-magic-numbers */
import Style from 'ol/style/Style';
import { Stroke } from 'ol/style';
import getScaledStrokeStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getScaledStrokeStyle';

export default function getScaledElementStyle(
  style: Style,
  strokeStyle: Stroke | undefined,
  scale: number,
): Style {
  if (strokeStyle) {
    style.setStroke(getScaledStrokeStyle(strokeStyle, scale));
  }
  style.getText()?.setScale(scale);
  return style;
}
