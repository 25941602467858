import { MapInstance } from '@/types/map';
import VectorSource from 'ol/source/Vector';

export default function removeElementFromLayer({
  mapInstance,
  layerId,
  featureId,
}: {
  mapInstance: MapInstance;
  layerId: number;
  featureId: number;
}): void {
  mapInstance?.getAllLayers().forEach(layer => {
    if (layer.get('id') === layerId) {
      const source = layer.getSource();
      if (source instanceof VectorSource) {
        const feature = source.getFeatureById(featureId);
        if (feature) {
          source.removeFeature(feature);
        }
      }
    }
  });
}
