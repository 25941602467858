import { z } from 'zod';
import { pageableSchema } from '@/models/pageableSchema';
import { authorSchema } from './authorSchema';
import { referenceSchema } from './referenceSchema';

export const mapModelSchema = z.object({
  /** map id */
  id: z.number(),
  /** name of the map */
  name: z.string(),
  description: z.string(),
  /** map width */
  width: z.number(),
  /** map height */
  height: z.number(),
  /** size of the png tile used to visualize in frontend */
  tileSize: z.number(),
  /** default x center used in frontend visualization */
  defaultCenterX: z.number().nullable(),
  /** default y center used in frontend visualization */
  defaultCenterY: z.number().nullable(),
  /** default zoom level used in frontend visualization */
  defaultZoomLevel: z.number().nullable(),
  /** minimum zoom level available for the map */
  minZoom: z.number(),
  /** maximum zoom level available for the map */
  maxZoom: z.number(),
  authors: z.array(authorSchema),
  references: z.array(referenceSchema),
  creationDate: z.string().nullable(),
  modificationDates: z.array(z.string()),
  vectorRendering: z.boolean().optional(),
});

export const mapModelsSchema = pageableSchema(mapModelSchema);
