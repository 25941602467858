import VectorSource from 'ol/source/Vector';
import { LayerImage } from '@/types/models';
import { MapInstance } from '@/types/map';

export default function updateGlyph(
  mapInstance: MapInstance,
  layerId: number,
  layerImage: LayerImage,
): void {
  mapInstance?.getAllLayers().forEach(layer => {
    if (layer.get('id') === layerId) {
      const source = layer.getSource();
      if (source instanceof VectorSource) {
        const feature = source.getFeatureById(layerImage.id);
        const update = feature?.get('update');
        if (update && update instanceof Function) {
          update(layerImage);
          feature.changed();
        }
      }
    }
  });
}
