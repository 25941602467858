import { BioEntity } from '@/types/models';
import { showToast } from '@/utils/showToast';

export const handleOpenImmediateLink = (bioEntity: BioEntity): void => {
  const link = bioEntity.immediateLink;
  if (link !== null) {
    const tab = window.open(link, '_blank');
    if (tab) {
      tab.focus();
    } else {
      showToast({
        type: 'error',
        message: `Browser prevented minerva from opening link: <a href="${link}" target="_blank">${link}</a>`,
      });
    }
  }
};
