import { z } from 'zod';
import { licenseSchema } from '@/models/licenseSchema';
import { disease } from './disease';
import { organism } from './organism';
import { overviewImageView } from './overviewImageView';

export const projectSchema = z.object({
  id: z.number().int().nonnegative(),
  version: z.string(),
  disease: disease.nullable(),
  diseaseName: z.string().nullable(),
  organism: organism.nullable(),
  organismName: z.string().nullable(),
  status: z.string(),
  directory: z.string(),
  progress: z.number(),
  notifyEmail: z.string(),
  logEntries: z.boolean(),
  name: z.string(),
  sharedInMinervaNet: z.boolean(),
  owner: z.object({
    login: z.string(),
  }),
  projectId: z.string(),
  creationDate: z.string(),
  overviewImageViews: z.array(overviewImageView),
  topOverviewImage: overviewImageView.nullable(),
  license: z.optional(licenseSchema).nullable(),
  customLicenseName: z.string(),
  customLicenseUrl: z.string(),
  topMap: z.object({
    id: z.number().int().nonnegative(),
  }),
});
