import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { overlaysDataSelector } from '@/redux/overlays/overlays.selectors';
import { OverlayListItem } from './OverlayListItem';

export const GeneralOverlays = (): JSX.Element => {
  const generalPublicOverlays = useAppSelector(overlaysDataSelector);

  return (
    <div className="border-b border-b-divide p-6">
      <p className="mb-5 text-sm font-semibold">Shared Overlays:</p>
      <ul>
        {generalPublicOverlays.map(overlay => (
          <OverlayListItem
            key={overlay.idObject}
            name={overlay.name}
            overlayId={overlay.idObject}
          />
        ))}
      </ul>
    </div>
  );
};
