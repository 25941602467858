/* eslint-disable no-magic-numbers */
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useEffect, useMemo, useState } from 'react';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import MapElement from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/MapElement';
import { useSelector } from 'react-redux';
import {
  arrowTypesSelector,
  bioShapesSelector,
  lineTypesSelector,
} from '@/redux/shapes/shapes.selectors';
import { MapInstance } from '@/types/map';
import {
  modelElementsForCurrentModelSelector,
  modelElementsLoadingSelector,
} from '@/redux/modelElements/modelElements.selector';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { getModelElementsForModel } from '@/redux/modelElements/modelElements.thunks';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import CompartmentSquare from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/CompartmentSquare';
import CompartmentCircle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/CompartmentCircle';
import Glyph from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/Glyph/Glyph';
import CompartmentPathway from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/CompartmentPathway';
import Reaction from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/reaction/Reaction';
import {
  newReactionsForCurrentModelSelector,
  newReactionsLoadingSelector,
} from '@/redux/newReactions/newReactions.selectors';
import { getNewReactionsForModel } from '@/redux/newReactions/newReactions.thunks';
import { VECTOR_MAP_LAYER_TYPE } from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import {
  getOverlayOrderSelector,
  overlayBioEntitiesForCurrentModelSelector,
} from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { groupBy } from '@/utils/array/groupBy';
import { useGetOverlayColor } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import getOverlays from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/overlay/getOverlays';
import LineOverlay from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/overlay/LineOverlay';
import { markersSufraceOfCurrentMapDataSelector } from '@/redux/markers/markers.selectors';
import { parseSurfaceMarkersToBioEntityRender } from '@/components/Map/MapViewer/utils/config/overlaysLayer/parseSurfaceMarkersToBioEntityRender';
import MarkerOverlay from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/overlay/MarkerOverlay';
import processModelElements from '@/components/Map/MapViewer/MapViewerVector/utils/config/reactionsLayer/processModelElements';
import useDebouncedValue from '@/utils/useDebouncedValue';
import { mapBackgroundTypeSelector, mapDataSizeSelector } from '@/redux/map/map.selectors';
import MapBackgroundsEnum from '@/redux/map/map.enums';
import { setMapBackgroundType } from '@/redux/map/map.slice';
import { ZOOM_RESCALING_FACTOR } from '@/constants/map';
import { OverlayOrder } from '@/redux/overlayBioEntity/overlayBioEntity.utils';
import areOverlayOrdersNotEqual from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/overlay/areOverlayOrdersNotEqual';

export const useOlMapReactionsLayer = ({
  mapInstance,
}: {
  mapInstance: MapInstance;
}): VectorLayer<VectorSource<Feature>> => {
  const dispatch = useAppDispatch();

  const [overlaysOrderState, setOverlaysOrderState] = useState<Array<OverlayOrder>>([]);
  const currentModelId = useSelector(currentModelIdSelector);
  const shapes = useSelector(bioShapesSelector);
  const mapSize = useSelector(mapDataSizeSelector);
  const lineTypes = useSelector(lineTypesSelector);
  const arrowTypes = useSelector(arrowTypesSelector);
  const overlaysOrder = useSelector(getOverlayOrderSelector);
  const mapBackgroundType = useSelector(mapBackgroundTypeSelector);
  const currentMarkers = useAppSelector(markersSufraceOfCurrentMapDataSelector);
  const markersRender = useMemo(() => {
    return parseSurfaceMarkersToBioEntityRender(currentMarkers);
  }, [currentMarkers]);

  const bioEntities = useAppSelector(overlayBioEntitiesForCurrentModelSelector);
  const reactionsForCurrentModel = useAppSelector(newReactionsForCurrentModelSelector);
  const modelElementsLoading = useAppSelector(modelElementsLoadingSelector);
  const reactionsLoading = useAppSelector(newReactionsLoadingSelector);

  const modelElementsForCurrentModel = useAppSelector(modelElementsForCurrentModelSelector);
  const debouncedBioEntities = useDebouncedValue(bioEntities, 1000);
  const { getOverlayBioEntityColorByAvailableProperties } = useGetOverlayColor();

  const pointToProjection = usePointToProjection();

  const vectorSource = useMemo(() => new VectorSource(), []);

  const mapModelOriginalMaxZoom = mapInstance?.getView().get('originalMaxZoom');

  const isCorrectMapInstanceViewScale = useMemo(() => {
    return mapSize.maxZoom * ZOOM_RESCALING_FACTOR === mapModelOriginalMaxZoom;
  }, [mapModelOriginalMaxZoom, mapSize.maxZoom]);

  useEffect(() => {
    if (areOverlayOrdersNotEqual(overlaysOrderState, overlaysOrder)) {
      setOverlaysOrderState(overlaysOrder);
    }
  }, [overlaysOrder, overlaysOrderState]);

  useEffect(() => {
    if (!currentModelId) {
      return;
    }
    if (!['succeeded', 'pending'].includes(modelElementsLoading)) {
      dispatch(getModelElementsForModel(currentModelId));
    }
    if (!['succeeded', 'pending'].includes(reactionsLoading)) {
      dispatch(getNewReactionsForModel(currentModelId));
    }
  }, [currentModelId, dispatch, reactionsLoading, modelElementsLoading]);

  useEffect(() => {
    if (overlaysOrderState.length) {
      dispatch(setMapBackgroundType(MapBackgroundsEnum.NETWORK));
    }
  }, [dispatch, overlaysOrderState]);

  const groupedElementsOverlays = useMemo(() => {
    const elementsBioEntitesOverlay = debouncedBioEntities.filter(
      bioEntity => bioEntity.type !== 'line',
    );
    const grouped = groupBy(elementsBioEntitesOverlay, bioEntity => bioEntity.id.toString());
    return getOverlays(grouped, getOverlayBioEntityColorByAvailableProperties);
  }, [debouncedBioEntities, getOverlayBioEntityColorByAvailableProperties]);

  const linesOverlays = useMemo(() => {
    return bioEntities.filter(bioEntity => bioEntity.type === 'line');
  }, [bioEntities]);

  const linesOverlaysFeatures = useMemo(() => {
    if (!isCorrectMapInstanceViewScale) {
      return [];
    }
    return linesOverlays.map(lineOverlay => {
      return new LineOverlay({
        lineOverlay,
        getOverlayColor: getOverlayBioEntityColorByAvailableProperties,
        pointToProjection,
        mapInstance,
      }).lineFeature;
    });
  }, [
    getOverlayBioEntityColorByAvailableProperties,
    isCorrectMapInstanceViewScale,
    linesOverlays,
    mapInstance,
    pointToProjection,
  ]);

  const markerOverlaysFeatures = useMemo(() => {
    if (!isCorrectMapInstanceViewScale) {
      return [];
    }
    return markersRender.map(marker => {
      return new MarkerOverlay({
        markerOverlay: marker,
        getOverlayColor: getOverlayBioEntityColorByAvailableProperties,
        pointToProjection,
        mapInstance,
      }).markerFeature;
    });
  }, [
    getOverlayBioEntityColorByAvailableProperties,
    isCorrectMapInstanceViewScale,
    mapInstance,
    markersRender,
    pointToProjection,
  ]);

  const reactions = useMemo(() => {
    if (!reactionsForCurrentModel || !isCorrectMapInstanceViewScale) {
      return [];
    }
    return reactionsForCurrentModel.map(reaction => {
      const reactionShapes = shapes && shapes[reaction.sboTerm];
      if (!reactionShapes) {
        return [];
      }
      const reactionObject = new Reaction({
        id: reaction.id,
        line: reaction.line,
        products: reaction.products,
        reactants: reaction.reactants,
        modifiers: reaction.modifiers,
        operators: reaction.operators,
        zIndex: reaction.z,
        lineTypes,
        arrowTypes,
        shapes: reactionShapes,
        pointToProjection,
        vectorSource,
        mapInstance,
      });
      return [reactionObject.lineFeature, ...reactionObject.reactionFeatures];
    });
  }, [
    reactionsForCurrentModel,
    isCorrectMapInstanceViewScale,
    shapes,
    lineTypes,
    arrowTypes,
    pointToProjection,
    vectorSource,
    mapInstance,
  ]);

  const elements: Array<
    MapElement | CompartmentCircle | CompartmentSquare | CompartmentPathway | Glyph
  > = useMemo(() => {
    if (!modelElementsForCurrentModel || !shapes || !isCorrectMapInstanceViewScale) {
      return [];
    }
    return processModelElements(
      modelElementsForCurrentModel,
      shapes,
      lineTypes,
      groupedElementsOverlays,
      overlaysOrderState,
      getOverlayBioEntityColorByAvailableProperties,
      vectorSource,
      mapInstance,
      pointToProjection,
      mapBackgroundType,
      mapSize,
    );
  }, [
    modelElementsForCurrentModel,
    shapes,
    isCorrectMapInstanceViewScale,
    lineTypes,
    groupedElementsOverlays,
    overlaysOrderState,
    getOverlayBioEntityColorByAvailableProperties,
    vectorSource,
    mapInstance,
    pointToProjection,
    mapBackgroundType,
    mapSize,
  ]);

  const features = useMemo(() => {
    const reactionsFeatures = reactions.flat();
    const elementsFeatures = elements.map(element => element.feature);
    return [
      ...reactionsFeatures,
      ...elementsFeatures,
      ...linesOverlaysFeatures,
      ...markerOverlaysFeatures,
    ];
  }, [elements, linesOverlaysFeatures, markerOverlaysFeatures, reactions]);

  useEffect(() => {
    vectorSource.clear();
    vectorSource.addFeatures(features);
  }, [features, vectorSource]);

  return useMemo(() => {
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });
    vectorLayer.set('type', VECTOR_MAP_LAYER_TYPE);
    return vectorLayer;
  }, [vectorSource]);
};
