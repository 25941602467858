import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useMemo } from 'react';
import Polygon from 'ol/geom/Polygon';
import { useSelector } from 'react-redux';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import Style from 'ol/style/Style';
import { Fill } from 'ol/style';

export const useOlMapCardLayer = (): VectorLayer<VectorSource<Feature<Polygon>>> => {
  const mapSize = useSelector(mapDataSizeSelector);
  const pointToProjection = usePointToProjection();

  const rectangle = useMemo(() => {
    return new Polygon([
      [
        pointToProjection({ x: 0, y: 0 }),
        pointToProjection({ x: mapSize.width, y: 0 }),
        pointToProjection({ x: mapSize.width, y: mapSize.height }),
        pointToProjection({ x: 0, y: mapSize.height }),
        pointToProjection({ x: 0, y: 0 }),
      ],
    ]);
  }, [mapSize.height, mapSize.width, pointToProjection]);

  const rectangleFeature = useMemo(() => {
    return new Feature(rectangle);
  }, [rectangle]);

  const vectorSource = useMemo(() => {
    return new VectorSource({
      features: [rectangleFeature],
    });
  }, [rectangleFeature]);

  return useMemo(
    () =>
      new VectorLayer({
        source: vectorSource,
        style: new Style({
          fill: new Fill({
            color: '#fff',
          }),
        }),
      }),
    [vectorSource],
  );
};
