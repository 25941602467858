import { apiPath } from '@/redux/apiPath';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { MapModel, MapModels } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { mapModelsSchema } from '@/models/modelSchema';
import { MODELS_FETCHING_ERROR_PREFIX } from './models.constants';

export const getModels = createAsyncThunk<MapModel[] | undefined, void, ThunkConfig>(
  'project/getModels',
  async () => {
    try {
      const response = await axiosInstanceNewAPI.get<MapModels>(apiPath.getModelsString());

      const isDataValid = validateDataUsingZodSchema(response.data, mapModelsSchema);

      return isDataValid ? response.data.content : undefined;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: MODELS_FETCHING_ERROR_PREFIX }));
    }
  },
);
