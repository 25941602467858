import React, { InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type StyleVariant = 'primary' | 'primaryWithoutFull';
type SizeVariant = 'small' | 'medium';

type InputProps = {
  className?: string;
  styleVariant?: StyleVariant;
  sizeVariant?: SizeVariant;
  ref?: React.Ref<HTMLInputElement>;
} & InputHTMLAttributes<HTMLInputElement>;

const styleVariants = {
  primary:
    'w-full border border-transparent bg-cultured px-2 py-2.5 font-semibold outline-none hover:border-greyscale-600 focus:border-greyscale-600',
  primaryWithoutFull:
    'border border-transparent bg-cultured px-2 py-2.5 font-semibold outline-none hover:border-greyscale-600 focus:border-greyscale-600',
} as const;

const sizeVariants = {
  small: 'rounded-s h-10 text-xs',
  medium: 'rounded-lg h-12 text-sm',
} as const;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className = '', sizeVariant = 'small', styleVariant = 'primary', ...props }: InputProps,
    ref,
  ): React.ReactNode => (
    <input
      ref={ref}
      {...props}
      className={twMerge(styleVariants[styleVariant], sizeVariants[sizeVariant], className)}
    />
  ),
);

Input.displayName = 'Input';
