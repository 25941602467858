import { FIRST_ARRAY_ELEMENT } from '@/constants/common';
import { currentBackgroundSelector } from '@/redux/backgrounds/background.selectors';
import { downloadElements, downloadNetwork } from '@/redux/export/export.thunks';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  currentModelIdSelector,
  modelsDataSelector,
  modelsIdsSelector,
} from '@/redux/models/models.selectors';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { activeOverlaysIdSelector } from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { DownloadCurrentView } from '@/components/Map/Drawer/ExportDrawer/ExportCompound/DownloadCurrentView/DownloadCurrentView.component';
import { getZoom } from '@/services/pluginsManager/map/zoom/getZoom';
import { CheckboxItem } from '../CheckboxFilter/CheckboxFilter.types';
import { Annotations } from './Annotations';
import { DownloadElements } from './DownloadElements/DownloadElements';
import { DownloadGraphics } from './DownloadGraphics';
import { DownloadNetwork } from './DownloadNetwork/DownloadNetwork';
import { ExcludedCompartmentPathways } from './ExcludedCompartmentPathways';
import { ELEMENTS_COLUMNS, NETWORK_COLUMNS } from './ExportCompound.constant';
import { ExportContext } from './ExportCompound.context';
import { ImageFormat } from './ImageFormat';
import { ImageSize } from './ImageSize';
import { DEFAULT_IMAGE_SIZE } from './ImageSize/ImageSize.constants';
import { ImageSize as ImageSizeType } from './ImageSize/ImageSize.types';
import { IncludedCompartmentPathways } from './IncludedCompartmentPathways ';
import { Submap } from './Submap';
import { getDownloadElementsBodyRequest } from './utils/getDownloadElementsBodyRequest';
import { getGraphicsDownloadUrl } from './utils/getGraphicsDownloadUrl';
import { getModelExportZoom } from './utils/getModelExportZoom';
import { getNetworkDownloadBodyRequest } from './utils/getNetworkBodyRequest';

type ExportProps = {
  children: ReactNode;
};

export const Export = ({ children }: ExportProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const modelIds = useAppSelector(modelsIdsSelector);
  const selectedModelId = useAppSelector(currentModelIdSelector);

  const currentModels = useAppSelector(modelsDataSelector);
  const currentBackground = useAppSelector(currentBackgroundSelector);
  const overlays = useAppSelector(activeOverlaysIdSelector);
  const [annotations, setAnnotations] = useState<CheckboxItem[]>([]);
  const [includedCompartmentPathways, setIncludedCompartmentPathways] = useState<CheckboxItem[]>(
    [],
  );
  const [excludedCompartmentPathways, setExcludedCompartmentPathways] = useState<CheckboxItem[]>(
    [],
  );
  const [models, setModels] = useState<CheckboxItem[]>([]);
  const [imageSize, setImageSize] = useState<ImageSizeType>(DEFAULT_IMAGE_SIZE);
  const [imageFormats, setImageFormats] = useState<CheckboxItem[]>([]);

  const handleDownloadElements = useCallback(async () => {
    const body = getDownloadElementsBodyRequest({
      columns: ELEMENTS_COLUMNS,
      modelIds,
      annotations,
      includedCompartmentPathways,
      excludedCompartmentPathways,
    });
    await dispatch(downloadElements(body));
  }, [modelIds, annotations, includedCompartmentPathways, excludedCompartmentPathways, dispatch]);

  const handleDownloadNetwork = useCallback(async () => {
    const data = getNetworkDownloadBodyRequest({
      columns: NETWORK_COLUMNS,
      modelIds,
      annotations,
      includedCompartmentPathways,
      excludedCompartmentPathways,
    });

    await dispatch(downloadNetwork(data));
  }, [modelIds, annotations, includedCompartmentPathways, excludedCompartmentPathways, dispatch]);

  const handleDownloadGraphics = useCallback(async () => {
    const modelId = models?.[FIRST_ARRAY_ELEMENT]?.id;
    const model = currentModels.find(currentModel => currentModel.id === Number(modelId));

    const url = getGraphicsDownloadUrl({
      backgroundId: currentBackground?.id,
      modelId: models?.[FIRST_ARRAY_ELEMENT]?.id,
      handler: imageFormats?.[FIRST_ARRAY_ELEMENT]?.id,
      zoom: getModelExportZoom(imageSize.width, model),
      overlayIds: overlays.map(overlayId => `${overlayId}`),
      currentView: false,
    });

    if (url) {
      window.open(url);
    }
  }, [models, imageFormats, currentBackground, currentModels, imageSize.width, overlays]);

  const handleDownloadCurrentView = useCallback(async () => {
    const url = getGraphicsDownloadUrl({
      backgroundId: currentBackground?.id,
      modelId: `${selectedModelId}`,
      handler: imageFormats?.[FIRST_ARRAY_ELEMENT]?.id,
      zoom: getZoom(),
      overlayIds: overlays.map(overlayId => `${overlayId}`),
      currentView: true,
    });

    if (url) {
      window.open(url);
    }
  }, [selectedModelId, imageFormats, currentBackground, overlays]);

  const globalContextDataValue = useMemo(
    () => ({
      annotations,
      includedCompartmentPathways,
      excludedCompartmentPathways,
      models,
      imageSize,
      imageFormats,
    }),
    [
      annotations,
      includedCompartmentPathways,
      excludedCompartmentPathways,
      models,
      imageSize,
      imageFormats,
    ],
  );

  const globalContextValue = useMemo(
    () => ({
      setAnnotations,
      setIncludedCompartmentPathways,
      setExcludedCompartmentPathways,
      setModels,
      setImageSize,
      setImageFormats,
      handleDownloadElements,
      handleDownloadNetwork,
      handleDownloadGraphics,
      handleDownloadCurrentView,
      data: globalContextDataValue,
    }),
    [
      handleDownloadElements,
      handleDownloadNetwork,
      globalContextDataValue,
      handleDownloadGraphics,
      handleDownloadCurrentView,
    ],
  );

  return <ExportContext.Provider value={globalContextValue}>{children}</ExportContext.Provider>;
};

Export.Annotations = Annotations;
Export.IncludedCompartmentPathways = IncludedCompartmentPathways;
Export.ExcludedCompartmentPathways = ExcludedCompartmentPathways;
Export.DownloadElements = DownloadElements;
Export.Submap = Submap;
Export.ImageSize = ImageSize;
Export.ImageFormat = ImageFormat;
Export.DownloadNetwork = DownloadNetwork;
Export.DownloadGraphics = DownloadGraphics;
Export.DownloadCurrentView = DownloadCurrentView;
