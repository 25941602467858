interface ImageIconProps {
  className?: string;
}

export const ImageIcon = ({ className }: ImageIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="18" height="18" rx="2" stroke="currentColor" strokeWidth="1.5" />
    <circle cx="8" cy="8" r="1.5" stroke="currentColor" strokeWidth="1.5" fill="none" />
    <path
      d="M4 18L9 13L12 16L16 12L20 18H4Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);
