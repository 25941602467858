import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { FilteredPageOf, Publication } from '@/types/models';
import { pageableSchema } from '@/models/pageableSchema';
import { publicationSchema } from '@/models/publicationsSchema';
import { GetPublicationsParams } from './publications.types';
import { apiPath } from '../apiPath';
import { PUBLICATIONS_FETCHING_ERROR_PREFIX } from './publications.constatns';

export const getPublications = createAsyncThunk<
  FilteredPageOf<Publication> | undefined,
  GetPublicationsParams,
  ThunkConfig
>('publications/getPublications', async params => {
  try {
    const response = await axiosInstanceNewAPI.get<FilteredPageOf<Publication>>(
      apiPath.getPublications(params),
    );

    const isDataValid = validateDataUsingZodSchema(
      response.data,
      pageableSchema(publicationSchema),
    );

    return isDataValid ? response.data : undefined;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: PUBLICATIONS_FETCHING_ERROR_PREFIX }));
  }
});
