/* eslint-disable no-magic-numbers */
import { LayerImage, LayerLine, LayerOval, LayerRect, LayerText } from '@/types/models';
import { MapInstance } from '@/types/map';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { LineString, MultiPolygon, Point } from 'ol/geom';
import Text from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/text/Text';
import Polygon from 'ol/geom/Polygon';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import getEllipseCoords from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getEllipseCoords';
import getStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getStyle';
import {
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import getRotation from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/coords/getRotation';
import getArrowFeature from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/getArrowFeature';
import { FeatureLike } from 'ol/Feature';
import Style from 'ol/style/Style';
import { ArrowTypeDict, LineTypeDict } from '@/redux/shapes/shapes.types';
import {
  LAYER_ELEMENT_TYPES,
  REACTION_ELEMENT_CUTOFF_SCALE,
  TRANSPARENT_COLOR,
} from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.constants';
import getScaledElementStyle from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/style/getScaledElementStyle';
import { Stroke } from 'ol/style';
import Glyph from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/elements/Glyph/Glyph';
import { MapSize } from '@/redux/map/map.types';

export interface LayerProps {
  texts: { [key: string]: LayerText };
  rects: Array<LayerRect>;
  ovals: Array<LayerOval>;
  lines: Array<LayerLine>;
  images: { [key: string]: LayerImage };
  visible: boolean;
  layerId: number;
  lineTypes: LineTypeDict;
  arrowTypes: ArrowTypeDict;
  mapInstance: MapInstance;
  mapSize: MapSize;
  pointToProjection: UsePointToProjectionResult;
}

export default class Layer {
  layerId: number;

  texts: { [key: string]: LayerText };

  rects: Array<LayerRect>;

  ovals: Array<LayerOval>;

  lines: Array<LayerLine>;

  images: { [key: string]: LayerImage };

  lineTypes: LineTypeDict;

  arrowTypes: ArrowTypeDict;

  pointToProjection: UsePointToProjectionResult;

  mapInstance: MapInstance;

  mapSize: MapSize;

  vectorSource: VectorSource<
    Feature<Point> | Feature<Polygon> | Feature<LineString> | Feature<MultiPolygon>
  >;

  vectorLayer: VectorLayer<
    VectorSource<Feature<Point> | Feature<Polygon> | Feature<LineString> | Feature<MultiPolygon>>
  >;

  constructor({
    texts,
    rects,
    ovals,
    lines,
    images,
    visible,
    layerId,
    lineTypes,
    arrowTypes,
    mapInstance,
    mapSize,
    pointToProjection,
  }: LayerProps) {
    this.vectorSource = new VectorSource({});

    this.texts = texts;
    this.rects = rects;
    this.ovals = ovals;
    this.lines = lines;
    this.images = images;
    this.lineTypes = lineTypes;
    this.arrowTypes = arrowTypes;
    this.pointToProjection = pointToProjection;
    this.mapInstance = mapInstance;
    this.mapSize = mapSize;
    this.layerId = layerId;

    this.vectorSource.addFeatures(this.getTextsFeatures());
    this.vectorSource.addFeatures(this.getRectsFeatures());
    this.vectorSource.addFeatures(this.getOvalsFeatures());
    const imagesFeatures = this.getImagesFeatures();
    this.vectorSource.addFeatures(imagesFeatures);

    const { linesFeatures, arrowsFeatures } = this.getLinesFeatures();
    this.vectorSource.addFeatures(linesFeatures);
    this.vectorSource.addFeatures(arrowsFeatures);

    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
      visible,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    this.vectorLayer.set('id', layerId);
    this.vectorLayer.set('imagesFeatures', imagesFeatures);
    this.vectorLayer.set('drawImage', this.drawImage.bind(this));
    this.vectorLayer.set('drawText', this.drawText.bind(this));
  }

  private getTextsFeatures = (): Array<Feature<Point>> => {
    const textObjects = Object.values(this.texts).map(text => {
      return new Text({
        x: text.x,
        y: text.y,
        zIndex: text.z,
        width: text.width,
        height: text.height,
        fontColor: text.color,
        fontSize: text.fontSize,
        text: text.notes,
        verticalAlign: text.verticalAlign as VerticalAlign,
        horizontalAlign: text.horizontalAlign as HorizontalAlign,
        pointToProjection: this.pointToProjection,
        mapInstance: this.mapInstance,
      });
    });
    return textObjects.map(text => text.feature);
  };

  private drawText(text: LayerText): void {
    const textFeature = this.getTextFeature(text);
    this.vectorSource.addFeature(textFeature);
  }

  private getTextFeature(text: LayerText): Feature<Point> {
    const textObject = new Text({
      x: text.x,
      y: text.y,
      zIndex: text.z,
      width: text.width,
      height: text.height,
      fontColor: text.color,
      fontSize: text.fontSize,
      text: text.notes,
      verticalAlign: text.verticalAlign as VerticalAlign,
      horizontalAlign: text.horizontalAlign as HorizontalAlign,
      pointToProjection: this.pointToProjection,
      mapInstance: this.mapInstance,
    });
    return textObject.feature;
  }

  private getRectsFeatures = (): Array<Feature<Polygon>> => {
    return this.rects.map(rect => {
      const polygon = new Polygon([
        [
          this.pointToProjection({ x: rect.x, y: rect.y }),
          this.pointToProjection({ x: rect.x + rect.width, y: rect.y }),
          this.pointToProjection({ x: rect.x + rect.width, y: rect.y + rect.height }),
          this.pointToProjection({ x: rect.x, y: rect.y + rect.height }),
        ],
      ]);
      const polygonStyle = getStyle({
        geometry: polygon,
        borderColor: rect.borderColor,
        fillColor: rect.fillColor,
        lineWidth: rect.lineWidth,
        zIndex: rect.z,
      });
      const rectFeature = new Feature<Polygon>({
        geometry: polygon,
        style: polygonStyle,
        lineWidth: rect.lineWidth,
        elementType: LAYER_ELEMENT_TYPES.RECT,
      });
      rectFeature.setStyle(this.getStyle.bind(this));
      return rectFeature;
    });
  };

  private getOvalsFeatures = (): Array<Feature<Polygon>> => {
    return this.ovals.map(oval => {
      const coords = getEllipseCoords({
        x: oval.x + oval.width / 2,
        y: oval.y + oval.height / 2,
        height: oval.height,
        width: oval.width,
        pointToProjection: this.pointToProjection,
        points: 20,
      });
      const polygon = new Polygon([coords]);
      const polygonStyle = getStyle({
        geometry: polygon,
        borderColor: oval.borderColor,
        fillColor: TRANSPARENT_COLOR,
        lineWidth: oval.lineWidth,
        zIndex: oval.z,
      });
      const ovalFeature = new Feature<Polygon>({
        geometry: polygon,
        style: polygonStyle,
        lineWidth: oval.lineWidth,
        elementType: LAYER_ELEMENT_TYPES.OVAL,
      });
      ovalFeature.setStyle(this.getStyle.bind(this));
      return ovalFeature;
    });
  };

  private getLinesFeatures = (): {
    linesFeatures: Array<Feature<LineString>>;
    arrowsFeatures: Array<Feature<MultiPolygon>>;
  } => {
    const linesFeatures: Array<Feature<LineString>> = [];
    const arrowsFeatures: Array<Feature<MultiPolygon>> = [];

    this.lines.forEach(line => {
      const points = line.segments
        .map((segment, index) => {
          if (index === 0) {
            return [
              this.pointToProjection({ x: segment.x1, y: segment.y1 }),
              this.pointToProjection({ x: segment.x2, y: segment.y2 }),
            ];
          }
          return [this.pointToProjection({ x: segment.x2, y: segment.y2 })];
        })
        .flat();

      if (line.startArrow.arrowType !== 'NONE') {
        const firstSegment = line.segments[0];
        const startArrowRotation = getRotation(
          [firstSegment.x1, firstSegment.y1],
          [firstSegment.x2, firstSegment.y2],
        );
        const shortenedX1 = firstSegment.x1 + line.startArrow.length * Math.cos(startArrowRotation);
        const shortenedY1 = firstSegment.y1 - line.startArrow.length * Math.sin(startArrowRotation);
        points[0] = this.pointToProjection({ x: shortenedX1, y: shortenedY1 });

        const startArrowFeature = getArrowFeature({
          arrowTypes: this.arrowTypes,
          arrow: line.startArrow,
          x: shortenedX1,
          y: shortenedY1,
          zIndex: line.z,
          rotation: startArrowRotation,
          lineWidth: line.width,
          color: line.color,
          pointToProjection: this.pointToProjection,
        });
        if (startArrowFeature) {
          startArrowFeature.set('elementType', LAYER_ELEMENT_TYPES.ARROW);
          startArrowFeature.set('lineWidth', line.width);
          startArrowFeature.setStyle(this.getStyle.bind(this));
          arrowsFeatures.push(startArrowFeature);
        }
      }

      if (line.endArrow.arrowType !== 'NONE') {
        const lastSegment = line.segments[line.segments.length - 1];
        const endArrowRotation = getRotation(
          [lastSegment.x1, lastSegment.y1],
          [lastSegment.x2, lastSegment.y2],
        );
        const shortenedX2 = lastSegment.x2 - line.endArrow.length * Math.cos(endArrowRotation);
        const shortenedY2 = lastSegment.y2 - line.endArrow.length * Math.sin(endArrowRotation);
        points[points.length - 1] = this.pointToProjection({ x: shortenedX2, y: shortenedY2 });

        const endArrowFeature = getArrowFeature({
          arrowTypes: this.arrowTypes,
          arrow: line.endArrow,
          x: shortenedX2,
          y: shortenedY2,
          zIndex: line.z,
          rotation: endArrowRotation,
          lineWidth: line.width,
          color: line.color,
          pointToProjection: this.pointToProjection,
        });
        if (endArrowFeature) {
          endArrowFeature.set('elementType', LAYER_ELEMENT_TYPES.ARROW);
          endArrowFeature.setStyle(this.getStyle.bind(this));
          arrowsFeatures.push(endArrowFeature);
        }
      }

      const lineString = new LineString(points);

      const lineDash = this.lineTypes[line.lineType] || [];
      const lineStyle = getStyle({
        geometry: lineString,
        borderColor: line.color,
        lineWidth: line.width,
        lineDash,
        zIndex: line.z,
      });
      const lineFeature = new Feature<LineString>({
        geometry: lineString,
        style: lineStyle,
        lineWidth: line.width,
        elementType: LAYER_ELEMENT_TYPES.LINE,
      });
      lineFeature.setStyle(this.getStyle.bind(this));
      linesFeatures.push(lineFeature);
    });
    return { linesFeatures, arrowsFeatures };
  };

  private getImagesFeatures(): Feature<Polygon>[] {
    return Object.values(this.images).map(image => {
      return this.getGlyphFeature(image);
    });
  }

  private drawImage(image: LayerImage): void {
    const glyphFeature = this.getGlyphFeature(image);
    const imagesFeatures = this.vectorLayer.get('imagesFeatures');
    if (imagesFeatures && Array.isArray(imagesFeatures)) {
      imagesFeatures.push(glyphFeature);
    }
    this.vectorSource.addFeature(glyphFeature);
  }

  private getGlyphFeature(image: LayerImage): Feature<Polygon> {
    const glyph = new Glyph({
      elementId: image.id,
      glyphId: image.glyph,
      x: image.x,
      y: image.y,
      width: image.width,
      height: image.height,
      zIndex: image.z,
      pointToProjection: this.pointToProjection,
      mapInstance: this.mapInstance,
      mapSize: this.mapSize,
    });
    return glyph.feature;
  }

  protected getStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    const styles: Array<Style> = [];
    const maxZoom = this.mapInstance?.getView().get('originalMaxZoom');
    const minResolution = this.mapInstance?.getView().getResolutionForZoom(maxZoom);
    const style = feature.get('style');
    if (!minResolution || !style) {
      return [];
    }

    const scale = minResolution / resolution;
    let strokeStyle: Stroke | undefined;
    const type = feature.get('elementType');

    if (type === LAYER_ELEMENT_TYPES.ARROW && scale <= REACTION_ELEMENT_CUTOFF_SCALE) {
      return [];
    }

    const stylesToProcess: Array<Style> = [];
    if (style instanceof Style) {
      stylesToProcess.push(style);
    } else if (Array.isArray(style)) {
      stylesToProcess.push(...style);
    }
    stylesToProcess.forEach(singleStyle => {
      const styleGeometry = singleStyle.getGeometry();
      if (styleGeometry instanceof Polygon || styleGeometry instanceof LineString) {
        strokeStyle = styleGeometry.get('strokeStyle');
      }
      styles.push(getScaledElementStyle(singleStyle, strokeStyle, scale));
    });

    return styles;
  }
}
