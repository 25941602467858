import { Export } from '../ExportCompound';

export const CurrentView = (): React.ReactNode => {
  return (
    <div data-testid="current-view-tab">
      <Export>
        <Export.ImageFormat />
        <Export.DownloadCurrentView />
      </Export>
    </div>
  );
};
