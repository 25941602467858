/* eslint-disable no-magic-numbers */
import { Stroke } from 'ol/style';

export default function getScaledStrokeStyle(strokeStyle: Stroke, scale: number): Stroke {
  const lineWidth = strokeStyle.getWidth() || 1;
  const lineDash = strokeStyle.getLineDash();
  let newLineDash: Array<number> = [];
  if (lineDash) {
    newLineDash = lineDash.map(width => width * scale);
  }
  return new Stroke({
    color: strokeStyle.getColor(),
    width: lineWidth * scale,
    lineDash: newLineDash,
  });
}
