/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { layerImageObjectFactoryStateSelector } from '@/redux/modal/modal.selector';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { highestZIndexSelector, layersActiveLayerSelector } from '@/redux/layers/layers.selectors';
import { addLayerImageObject } from '@/redux/layers/layers.thunks';
import { addGlyph } from '@/redux/glyphs/glyphs.thunks';
import { SerializedError } from '@reduxjs/toolkit';
import { showToast } from '@/utils/showToast';
import { closeModal } from '@/redux/modal/modal.slice';
import './LayerImageObjectForm.styles.css';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import { layerAddImage } from '@/redux/layers/layers.slice';
import { LayerImageObjectForm } from '@/components/FunctionalArea/Modal/LayerImageObjectModal/LayerImageObjectForm.component';
import drawElementOnLayer from '@/components/Map/MapViewer/MapViewerVector/utils/shapes/layer/utils/drawElementOnLayer';

export const LayerImageObjectFactoryModal: React.FC = () => {
  const currentModelId = useAppSelector(currentModelIdSelector);
  const activeLayer = useAppSelector(layersActiveLayerSelector);
  const layerImageObjectFactoryState = useAppSelector(layerImageObjectFactoryStateSelector);
  const dispatch = useAppDispatch();
  const highestZIndex = useAppSelector(highestZIndexSelector);
  const { mapInstance } = useMapInstance();

  const [selectedGlyph, setSelectedGlyph] = useState<number | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isSending, setIsSending] = useState<boolean>(false);

  const handleSubmit = async (): Promise<void> => {
    if (!layerImageObjectFactoryState || !activeLayer) {
      return;
    }
    setIsSending(true);
    try {
      let glyphId = selectedGlyph;
      if (file) {
        const data = await dispatch(addGlyph(file)).unwrap();
        if (!data) {
          return;
        }
        glyphId = data.id;
      }
      const imageData = await dispatch(
        addLayerImageObject({
          modelId: currentModelId,
          layerId: activeLayer,
          x: layerImageObjectFactoryState.x,
          y: layerImageObjectFactoryState.y,
          z: highestZIndex + 1,
          width: layerImageObjectFactoryState.width,
          height: layerImageObjectFactoryState.height,
          glyph: glyphId,
        }),
      ).unwrap();
      if (!imageData) {
        showToast({
          type: 'error',
          message: 'Error while adding the image',
        });
        return;
      }
      dispatch(
        layerAddImage({ modelId: currentModelId, layerId: activeLayer, layerImage: imageData }),
      );
      drawElementOnLayer({
        mapInstance,
        activeLayer,
        object: imageData,
        drawFunctionKey: 'drawImage',
      });
      showToast({
        type: 'success',
        message: 'A new image has been successfully added',
      });
      dispatch(closeModal());
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while adding a new image',
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <LayerImageObjectForm
      file={file}
      selectedGlyph={selectedGlyph}
      isSending={isSending}
      onSubmit={handleSubmit}
      setFile={setFile}
      setSelectedGlyph={setSelectedGlyph}
    />
  );
};
