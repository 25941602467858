import { ModalName } from '@/types/modal';
import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorData } from '@/utils/error-report/ErrorData';
import { BoundingBox } from '@/components/Map/MapViewer/MapViewerVector/MapViewerVector.types';
import { ModalState, OpenEditOverlayModalAction } from './modal.types';

const getOpenedModel = (state: ModalState): ModalName | null => {
  if (state.isOpen) {
    return state.modalName;
  }
  return null;
};

export const openModalReducer = (state: ModalState, action: PayloadAction<ModalName>): void => {
  state.isOpen = true;
  state.modalName = action.payload;
};

export const closeModalReducer = (state: ModalState): void => {
  state.isOpen = false;
  state.modalName = 'none';
};

export const openOverviewImagesModalByIdReducer = (
  state: ModalState,
  action: PayloadAction<number>,
): void => {
  state.isOpen = true;
  state.modalName = 'overview-images';
  state.modalTitle = 'Overview images';
  state.overviewImagesState = {
    imageId: action.payload,
  };
};

export const openMolArtModalByIdReducer = (
  state: ModalState,
  action: PayloadAction<string | undefined>,
): void => {
  state.isOpen = true;
  state.modalName = 'mol-art';
  state.modalTitle = 'MolArt';
  state.molArtState = {
    uniprotId: action.payload,
  };
};

export const openLoginModalReducer = (state: ModalState): void => {
  state.isOpen = true;
  state.modalName = 'login';
  state.modalTitle = 'You need to login';
};

export const openAddCommentModalReducer = (state: ModalState): void => {
  state.isOpen = true;
  state.modalName = 'add-comment';
  state.modalTitle = 'Add comment';
};

export const openLoggedInMenuModalReducer = (state: ModalState): void => {
  state.isOpen = true;
  state.modalName = 'logged-in-menu';
  state.modalTitle = 'Select';
};

export const openErrorReportModalReducer = (
  state: ModalState,
  action: PayloadAction<ErrorData | undefined>,
): void => {
  state.isOpen = true;
  state.modalName = 'error-report';
  state.modalTitle = 'An error occurred!';
  state.errorReportState = {
    errorData: action.payload,
  };
};

export const openAccessDeniedModalReducer = (state: ModalState): void => {
  state.isOpen = true;
  state.modalName = 'access-denied';
  state.modalTitle = 'Access denied!';
};

export const openSelectProjectModalReducer = (state: ModalState): void => {
  if (getOpenedModel(state) !== 'terms-of-service') {
    state.isOpen = true;
    state.modalName = 'select-project';
    state.modalTitle = 'Select project!';
  }
};

export const setOverviewImageIdReducer = (
  state: ModalState,
  action: PayloadAction<number>,
): void => {
  state.overviewImagesState = {
    imageId: action.payload,
  };
};

export const openPublicationsModalReducer = (state: ModalState): void => {
  state.isOpen = true;
  state.modalName = 'publications';
  state.modalTitle = 'Publications';
};

export const openEditOverlayModalReducer = (
  state: ModalState,
  action: OpenEditOverlayModalAction,
): void => {
  state.isOpen = true;
  state.modalName = 'edit-overlay';
  state.modalTitle = action.payload.name;
  state.editOverlayState = action.payload;
};

export const openLicenseModalReducer = (state: ModalState, action: PayloadAction<string>): void => {
  state.isOpen = true;
  state.modalName = 'license';
  state.modalTitle = `License: ${action.payload}`;
};

export const openToSModalReducer = (state: ModalState): void => {
  state.isOpen = true;
  state.modalName = 'terms-of-service';
  state.modalTitle = 'Terms of service!';
};

export const openLayerFactoryModalReducer = (
  state: ModalState,
  action: PayloadAction<number | undefined>,
): void => {
  state.layerFactoryState = { id: action.payload };
  state.isOpen = true;
  state.modalName = 'layer-factory';
  if (action.payload) {
    state.modalTitle = 'Edit layer';
  } else {
    state.modalTitle = 'Add new layer';
  }
};

export const openLayerImageObjectFactoryModalReducer = (
  state: ModalState,
  action: PayloadAction<BoundingBox>,
): void => {
  state.layerImageObjectFactoryState = action.payload;
  state.isOpen = true;
  state.modalName = 'layer-image-object-factory';
  state.modalTitle = 'Select glyph or upload file';
};

export const openLayerImageObjectEditFactoryModalReducer = (state: ModalState): void => {
  state.isOpen = true;
  state.modalName = 'layer-image-object-edit-factory';
  state.modalTitle = 'Edit image';
};

export const openLayerTextFactoryModalReducer = (
  state: ModalState,
  action: PayloadAction<BoundingBox>,
): void => {
  state.layerTextFactoryState = action.payload;
  state.modalName = 'layer-text-factory';
  state.modalTitle = 'Add text';
  state.isOpen = true;
};
