import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { modalSelector } from '@/redux/modal/modal.selector';
import dynamic from 'next/dynamic';
import { AccessDeniedModal } from '@/components/FunctionalArea/Modal/AccessDeniedModal/AccessDeniedModal.component';
import { AddCommentModal } from '@/components/FunctionalArea/Modal/AddCommentModal/AddCommentModal.component';
import { LicenseModal } from '@/components/FunctionalArea/Modal/LicenseModal';
import { ToSModal } from '@/components/FunctionalArea/Modal/ToSModal/ToSModal.component';
import {
  LayerImageObjectEditFactoryModal,
  LayerImageObjectFactoryModal,
} from '@/components/FunctionalArea/Modal/LayerImageObjectModal';
import { LayerTextFactoryModal } from '@/components/FunctionalArea/Modal/LayerTextFactoryModal/LayerTextFactoryModal.component';
import { EditOverlayModal } from './EditOverlayModal';
import { LoginModal } from './LoginModal';
import { ErrorReportModal } from './ErrorReportModal';
import { ModalLayout } from './ModalLayout';
import { OverviewImagesModal } from './OverviewImagesModal';
import { PublicationsModal } from './PublicationsModal';
import { LoggedInMenuModal } from './LoggedInMenuModal';
import { LayerFactoryModal } from './LayerFactoryModal';

const MolArtModal = dynamic(
  () => import('./MolArtModal/MolArtModal.component').then(mod => mod.MolArtModal),
  { ssr: false },
);

export const Modal = (): React.ReactNode => {
  const { isOpen, modalName } = useAppSelector(modalSelector);

  return (
    <>
      {isOpen && modalName === 'overview-images' && (
        <ModalLayout>
          <OverviewImagesModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'mol-art' && (
        <ModalLayout>
          <MolArtModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'login' && (
        <ModalLayout>
          <LoginModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'error-report' && (
        <ModalLayout>
          <ErrorReportModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'license' && (
        <ModalLayout>
          <LicenseModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'publications' && <PublicationsModal />}
      {isOpen && modalName === 'edit-overlay' && (
        <ModalLayout>
          <EditOverlayModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'logged-in-menu' && (
        <ModalLayout>
          <LoggedInMenuModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'access-denied' && (
        <ModalLayout>
          <AccessDeniedModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'terms-of-service' && (
        <ModalLayout>
          <ToSModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'select-project' && (
        <ModalLayout>
          <AccessDeniedModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'add-comment' && (
        <ModalLayout>
          <AddCommentModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'layer-factory' && (
        <ModalLayout>
          <LayerFactoryModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'layer-image-object-factory' && (
        <ModalLayout>
          <LayerImageObjectFactoryModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'layer-image-object-edit-factory' && (
        <ModalLayout>
          <LayerImageObjectEditFactoryModal />
        </ModalLayout>
      )}
      {isOpen && modalName === 'layer-text-factory' && (
        <ModalLayout>
          <LayerTextFactoryModal />
        </ModalLayout>
      )}
    </>
  );
};
