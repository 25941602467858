import 'ol/ol.css';
import { twMerge } from 'tailwind-merge';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { isMapEditToolsActiveSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import { useOlMap } from './utils/useOlMap';
import { MAP_VIEWER_ROLE } from './MapViewer.constants';

export const MapViewer = (): JSX.Element => {
  const { mapRef } = useOlMap();
  const isMapEditToolsActive = useAppSelector(isMapEditToolsActiveSelector);

  return (
    <div
      ref={mapRef}
      role={MAP_VIEWER_ROLE}
      className={twMerge(
        'absolute left-[88px] top-[104px] h-[calc(100%-104px)] w-[calc(100%-88px)] bg-white',
        isMapEditToolsActive ? 'bg-[#e4e2de]' : 'bg-white',
      )}
    />
  );
};
