export default function arrayToKeyValue<T, K extends keyof T>(
  array: T[],
  key: K,
): Record<T[K] & PropertyKey, T> {
  return array.reduce(
    (accumulator, currentItem) => {
      accumulator[currentItem[key] as T[K] & PropertyKey] = currentItem;
      return accumulator;
    },
    {} as Record<T[K] & PropertyKey, T>,
  );
}
